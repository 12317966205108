import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';
import {
  BooleanFilter,
  CreatedAt,
  CreateItemCacheKey,
  CreateItemCacheKeys,
  DateType,
  FetchItemCacheKey,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemErrorMessage,
  FetchItemFetched,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  IsLoading,
  MoneyType,
  NanoID,
  NanoIDFilter,
  AssociationNanoIDsFilter,
  PercentageType,
  PostItemCacheKeys,
  StringFilter,
  UpdatedAt,
  UpdateItemCacheKeys,
  UUID,
  FetchItemsGqlQuery,
  IdFilterType,
  NanoIdFilterType,
  UuidFilterType,
  DateFilterType,
  Currencies,
  FetchItemsCacheKey,
  UpdateItemGqlQuery,
  CreateItemGqlQuery,
  UpdateItemCacheKey,
  TextFilterType,
  FloatFilterType
} from '../../types';
import {
  AvBillingInfoAddress,
  AvBillingInfoCity,
  AvBillingInfoCompanyName,
  AvBillingInfoContactEmail,
  AvBillingInfoContactName,
  AvBillingInfoContactPhone,
  AvBillingInfoCountry,
  AvBillingInfoID,
  AvBillingInfoNanoID,
  AvBillingInfoState,
  AvBillingInfoZipCode,
  AvBillingInfoPaymentMethods,
  AvBillingInfoName,
  AvBillingInfoNotes,
  AvBillingInfoBankDetails,
  AvBillingInfoVatNumber
} from '../avBillingInfos/avBillingInfosTypes';
import {
  BillingInfoAddress,
  BillingInfoCity,
  BillingInfoCompanyName,
  BillingInfoContactEmail,
  BillingInfoFirstName,
  BillingInfoLastName,
  BillingInfoContactPhone,
  BillingInfoCountry,
  BillingInfoID,
  BillingInfoNanoID,
  BillingInfoState,
  BillingInfoZipCode,
  BillingInfoVatNumber
} from '../billingInfos/billingInfosTypes';
import {
  CompanyID,
  CompanyName,
  CompanyNanoID
} from '../companies/companiesTypes';

import { UserID } from '../users/usersTypes';
import {
  ProjectID,
  ProjectName,
  ProjectOwnerFullName,
  ProjectOwnerNanoID
} from '../projects/projectsTypes';
import {
  SmartContractRuleCompetencyName,
  SmartContractRuleID,
  SmartContractRuleValue,
  SmartContractTaskCreatedAt,
  SmartContractTaskName,
  SmartContractTaskNanoID,
  SmartContractTaskProjectCompanyID,
  SmartContractTaskProjectCompanyName,
  SmartContractTaskProjectCompanyNanoID,
  SmartContractTaskProjectCreatedAt,
  SmartContractTaskProjectName,
  SmartContractTaskProjectNanoID,
  SmartContractTaskUUID
} from '../smartContracts/smartContractsTypes';
import { ItemID } from '../items/itemsTypes';
import { ItemTypeName } from '../itemTypes/itemTypesTypes';
import { TaskOwnerFullName, TaskOwnerNanoID } from '../tasks/tasksTypes';
import { JournalRecordImportRowJournalRecordImportId } from '../journalRecordImportRows/journalRecordImportRowsTypes';
import { CurrencyRateID } from '../currencyRates/currencyRatesTypes';
import { InvoiceItemsGroupBy } from './invoicesConstants';
import {
  GeneralLedgerGeneralLedgerType,
  GeneralLedgerID,
  GeneralLedgerName
} from '../generalLedgers/generalLedgersTypes';
import {
  ProformaInvoiceFormGroupByMode,
  ProformaInvoiceFormViewMode
} from '../teams/components/forms/ProformaInvoiceForm/ProformaInvoiceForm.types';

export type InvoiceID = ID;
export type InvoiceUUID = UUID;
export type InvoiceNanoID = NanoID;
export type InvoiceAmount = number;
export type InvoiceAccountTransactionCount = number;
export type InvoiceInvoiceType = InvoiceTypes;
export type InvoiceStatus = InvoiceStatuses;
export type InvoiceCreatedAt = CreatedAt;
export type InvoiceUpdatedAt = UpdatedAt;
export type InvoiceIssueDate = DateType;
export type InvoiceDueDate = DateType;
export type InvoiceNotes = string;
export type InvoiceTerms = string;
export type InvoiceTotal = MoneyType;
export type InvoiceTotalConverted = MoneyType;
export type InvoiceTempTotalConverted = MoneyType;
export type InvoiceSmartContractID = ID;
export type InvoicePaymentMethod = InvoicePaymentMethods;
export type InvoiceParentInvoiceID = InvoiceID;
export type InvoiceParentInvoiceNanoID = InvoiceNanoID;
export type InvoiceGeneralLedgerID = GeneralLedgerID;
export type InvoiceGeneralLedgerName = GeneralLedgerName;
export type InvoiceGeneralLedgerGeneralLedgerType =
  GeneralLedgerGeneralLedgerType;
export type InvoiceSelectedCurrencyRate = number;
export type InvoiceSelectedCurrency = Currencies;
export type InvoiceBurning = boolean;
export type InvoiceShowPayWireButton = boolean;
export type InvoiceDisabledPayWireButton = boolean;
export type InvoiceShowUncollectibleButton = boolean;
export type InvoiceDisabledUncollectibleButton = boolean;
export type InvoiceShowMuteButton = boolean;
export type InvoiceDisabledMuteButton = boolean;
export type InvoiceShowUnmuteButton = boolean;
export type InvoiceDisabledUnmuteButton = boolean;
export type InvoiceShowBindButton = boolean;
export type InvoiceDisabledBindButton = boolean;
export type InvoiceShowUnbindButton = boolean;
export type InvoiceDisabledUnbindButton = boolean;
export type InvoiceShowPayDepositButton = boolean;
export type InvoiceDisabledPayDepositButton = boolean;
export type InvoiceShowPayCardButton = boolean;
export type InvoiceDisabledPayCardButton = boolean;
export type InvoiceShowSendButton = boolean;
export type InvoiceDisabledSendButton = boolean;
export type InvoiceShowRefundButton = boolean;
export type InvoiceDisabledRefundButton = boolean;
export type InvoiceShowCancelButton = boolean;
export type InvoiceDisabledCancelButton = boolean;
export type InvoiceShowVoidButton = boolean;
export type InvoiceDisabledVoidButton = boolean;
export type InvoiceShowPayContractorsPfrButton = boolean;
export type InvoiceDisabledPayContractorsPfrButton = boolean;
export type InvoiceShowPayContractorsAvButton = boolean;
export type InvoiceDisabledPayContractorsAvButton = boolean;
export type InvoiceShowCancelSmartContractsButton = boolean;
export type InvoicePrepayment = PercentageType;
export type InvoicePrepaymentAmount = MoneyType;
export type InvoicePaid = number;
export type InvoiceReceived = number;
export type InvoiceTransactionFeeExpense = number;
export type InvoicePaymentDate = DateType;
export type InvoicePrimaryInvoiceID = InvoiceID;
export type InvoiceSecondaryInvoiceID = InvoiceID;
export type InvoiceSecondaryInvoiceNanoID = InvoiceNanoID;
export type InvoiceClientId = ID;
export type InvoiceClientStatus = InvoiceStatuses;
export type InvoiceProjectID = ProjectID;
export type InvoiceComment = string;
export type InvoiceUserId = UserID;
export type InvoicePaidAt = DateType;
export type InvoiceJournalRecordImportID =
  JournalRecordImportRowJournalRecordImportId;
export type InvoiceProcessingAt = DateType;
export type InvoiceName = string;

export type InvoiceGeneralLedgerCompanyID = CompanyID;
export type InvoiceGeneralLedgerCompanyNanoID = CompanyNanoID;
export type InvoiceGeneralLedgerCompanyName = CompanyName;

export type InvoiceCurrencyRateID = CurrencyRateID;
export type InvoiceInvoiceBillingInfoID = BillingInfoID;
export type InvoiceInvoiceBillingInfoNanoID = BillingInfoNanoID;
export type InvoiceInvoiceBillingInfoCompanyName = BillingInfoCompanyName;
export type InvoiceInvoiceBillingInfoFirstName = BillingInfoFirstName;
export type InvoiceInvoiceBillingInfoLastName = BillingInfoLastName;
export type InvoiceInvoiceBillingInfoContactPhone = BillingInfoContactPhone;
export type InvoiceInvoiceBillingInfoContactEmail = BillingInfoContactEmail;
export type InvoiceInvoiceBillingInfoCountry = BillingInfoCountry;
export type InvoiceInvoiceBillingInfoState = BillingInfoState;
export type InvoiceInvoiceBillingInfoCity = BillingInfoCity;
export type InvoiceInvoiceBillingInfoAddress = BillingInfoAddress;
export type InvoiceInvoiceBillingInfoZipCode = BillingInfoZipCode;
export type InvoiceInvoiceBillingInfoVatNumber = BillingInfoVatNumber;

export type InvoiceAvInvoiceBillingInfoID = AvBillingInfoID;
export type InvoiceAvInvoiceBillingInfoNanoID = AvBillingInfoNanoID;
export type InvoiceAvInvoiceBillingInfoName = AvBillingInfoName;
export type InvoiceAvInvoiceBillingInfoCompanyName = AvBillingInfoCompanyName;
export type InvoiceAvInvoiceBillingInfoContactName = AvBillingInfoContactName;
export type InvoiceAvInvoiceBillingInfoContactPhone = AvBillingInfoContactPhone;
export type InvoiceAvInvoiceBillingInfoContactEmail = AvBillingInfoContactEmail;
export type InvoiceAvInvoiceBillingInfoCountry = AvBillingInfoCountry;
export type InvoiceAvInvoiceBillingInfoState = AvBillingInfoState;
export type InvoiceAvInvoiceBillingInfoCity = AvBillingInfoCity;
export type InvoiceAvInvoiceBillingInfoAddress = AvBillingInfoAddress;
export type InvoiceAvInvoiceBillingInfoZipCode = AvBillingInfoZipCode;
export type InvoiceAvInvoiceBillingInfoPaymentMethods =
  AvBillingInfoPaymentMethods;
export type InvoiceAvInvoiceBillingInfoNotes = AvBillingInfoNotes;
export type InvoiceAvInvoiceBillingInfoBankDetails = AvBillingInfoBankDetails;
export type InvoiceAvInvoiceBillingInfoVatNumber = AvBillingInfoVatNumber;

export type FetchInvoiceErrorMessage = FetchItemErrorMessage;
export type FetchInvoiceFetched = FetchItemFetched;

export type InvoiceSmartContractTaskUUID = SmartContractTaskUUID;
export type InvoiceSmartContractTaskNanoID = SmartContractTaskNanoID;
export type InvoiceSmartContractTaskName = SmartContractTaskName;
export type InvoiceSmartContractTaskCreatedAt = SmartContractTaskCreatedAt;
export type InvoiceSmartContractTaskProjectNanoID =
  SmartContractTaskProjectNanoID;
export type InvoiceSmartContractTaskProjectName = SmartContractTaskProjectName;
export type InvoiceSmartContractTaskProjectCreatedAt =
  SmartContractTaskProjectCreatedAt;
export type InvoiceSmartContractTaskProjectCompanyID =
  SmartContractTaskProjectCompanyID;
export type InvoiceSmartContractTaskProjectCompanyNanoID =
  SmartContractTaskProjectCompanyNanoID;
export type InvoiceSmartContractTaskProjectCompanyName =
  SmartContractTaskProjectCompanyName;
export type InvoiceSmartContractTaskOwnerNanoID = TaskOwnerNanoID;
export type InvoiceSmartContractTaskOwnerFullName = TaskOwnerFullName;
export type InvoiceSmartContractProjectOwnerNanoID = ProjectOwnerNanoID;
export type InvoiceSmartContractProjectOwnerFullName = ProjectOwnerFullName;

export type InvoiceSmartContractRuleID = SmartContractRuleID;
export type InvoiceSmartContractRuleCompetencyName =
  SmartContractRuleCompetencyName;
export type InvoiceSmartContractRuleValue = SmartContractRuleValue;

export type InvoiceItemID = ItemID;
export type InvoiceItemTypeName = ItemTypeName;

export type GroupedInvoiceAmount = InvoiceAmount;
export type GroupedInvoiceCompanyName =
  InvoiceSmartContractTaskProjectCompanyName;
export type GroupedInvoiceCompanyNanoID =
  InvoiceSmartContractTaskProjectCompanyNanoID;
export type GroupedInvoiceDate = InvoiceCreatedAt;
export type GroupedInvoiceDueDate = InvoiceDueDate;
export type GroupedInvoiceShare = InvoiceSmartContractRuleValue;
export type GroupedInvoiceSmartContractID = InvoiceSmartContractID;
export type GroupedInvoiceSmartContractNetAmount = number;
export type GroupedInvoiceStatus = InvoiceStatus;
export type GroupedInvoiceTaskName = InvoiceSmartContractTaskName;
export type GroupedInvoiceTaskNanoID = InvoiceSmartContractTaskNanoID;
export type GroupedInvoiceParentInvoiceID = InvoiceParentInvoiceID;
export type GroupedInvoiceParentInvoiceGeneralLedgerCompanyNanoID =
  InvoiceGeneralLedgerCompanyNanoID;
export type GroupedItemSmartContractTaskProjectId = ProjectID;
export type GroupedItemSmartContractTaskProjectName = ProjectName;

export type InvoiceViewSettings = {
  viewMode: ProformaInvoiceFormViewMode;
  groupBy: ProformaInvoiceFormGroupByMode;
  enableGrouping: boolean;
};

export type InvoiceItemsGroupByType = Record<InvoiceItemsGroupBy, boolean>;

export type FetchInvoicesLimit = FetchItemsLimit;
export type FetchInvoicesPage = FetchItemsPage;
export type FetchInvoicesSort = FetchItemsSort;
export type FetchInvoicesSortInvoices = FetchItemsSortItems;
export type FetchInvoicesErrorMessage = FetchItemsErrorMessage;
export type FetchInvoicesFetched = FetchItemsFetched;
export type FetchInvoicesIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchInvoicesPaginateInvoices = FetchItemsPaginateItems;
export type FetchInvoicesPrefetchInvoices = FetchItemsPrefetchItems;
export type FetchInvoicesTotalCount = FetchItemsTotalCount;

export type FetchInvoicesChecked = Checked;
export type FetchInvoicesCheckedAll = CheckedAll;
export type FetchInvoicesOnCheckAll = OnCheckAll;
export type FetchInvoicesCheckedHash = CheckedHashItem;
export type FetchInvoicesOnSetCheckedIds = OnSetCheckedIds;

export type FetchInvoiceGqlQuery = FetchItemsGqlQuery;
export type FetchInvoicesGqlQuery = FetchItemsGqlQuery;

export type FetchInvoicesCacheKey = FetchItemsCacheKey;
export type FetchInvoicesCacheKeys = FetchItemCacheKey[];
export type CreateInvoiceCacheKeys = CreateItemCacheKeys;
export type CreateInvoiceFromProformaCacheKeys = CreateItemCacheKeys;
export type PayInvoiceCacheKey = CreateItemCacheKey;
export type PayInvoiceCacheKeys = PayInvoiceCacheKey[];
export type PayInvoiceErrorCacheKeys = PostItemCacheKeys;
export type VoidInvoiceCacheKeys = UpdateItemCacheKeys;
export type BatchVoidInvoiceCacheKeys = UpdateItemCacheKeys;
export type ChargebackInvoiceCacheKeys = UpdateItemCacheKeys;
export type CancelInvoiceCacheKeys = UpdateItemCacheKeys;
export type CancelInvoiceSmartContractsCacheKeys = UpdateItemCacheKeys;
export type UncollectibleInvoiceCacheKeys = UpdateItemCacheKeys;
export type SendInvoiceCacheKeys = UpdateItemCacheKeys;
export type PayFromDepositInvoiceCacheKeys = UpdateItemCacheKeys;
export type BatchPayFromDepositInvoiceCacheKeys = UpdateItemCacheKeys;
export type ContractorAllocateCacheKeys = UpdateItemCacheKeys;
export type ContractorPayBdeCacheKeys = UpdateItemCacheKeys;
export type SendToEmailInvoiceCacheKeys = UpdateItemCacheKeys;
export type ChangeCurrencyInvoiceCacheKeys = UpdateItemCacheKeys;
export type PayInternalInvoiceCacheKeys = UpdateItemCacheKeys;
export type UpdateBillingInfoInvoiceCacheKeys = UpdateItemCacheKeys;
export type UpdateDueDateInvoiceCacheKeys = UpdateItemCacheKeys;
export type UpdateDueDateInvoiceGqlQuery = UpdateItemGqlQuery;
export type UpdateIssueDateInvoiceCacheKeys = UpdateItemCacheKeys;
export type UpdateIssueDateInvoiceGqlQuery = UpdateItemGqlQuery;
export type UpdateViewSettingsInvoiceCacheKeys = UpdateItemCacheKeys;
export type UpdateViewSettingsInvoiceGqlQuery = UpdateItemGqlQuery;
export type CompanyCreateDraftInvoiceCacheKeys = CreateItemCacheKeys;
export type CompanyCompanyCreateDraftInvoiceGqlQuery = CreateItemGqlQuery;
export type FetchGroupedInvoicesCacheKey = FetchItemsCacheKey;
export type FetchGroupedInvoicesGqlQuery = FetchItemsGqlQuery;
export type InvoiceAddItemsCacheKeys = UpdateItemCacheKeys;
export type InvoiceRemoveItemsCacheKeys = UpdateItemCacheKeys;
export type AddItemsToDraftInvoiceCacheKey = UpdateItemCacheKey;
export type AddItemsToDraftInvoiceCacheKeys = AddItemsToDraftInvoiceCacheKey[];
export type RemoveItemsFromDraftInvoiceCacheKey = UpdateItemCacheKey;
export type RemoveItemsFromDraftInvoiceCacheKeys =
  RemoveItemsFromDraftInvoiceCacheKey[];
export type InvoiceSendDraftCacheKey = UpdateItemCacheKey;
export type InvoiceSendDraftCacheKeys = InvoiceSendDraftCacheKey[];
export type InvoiceSendDraftGqlQuery = UpdateItemGqlQuery;

export type FetchGroupedInvoicesLimit = FetchItemsLimit;
export type FetchGroupedInvoicesPage = FetchItemsPage;
export type FetchGroupedInvoicesSortGroupedInvoices = FetchItemsSortItems;
export type FetchGroupedInvoicesSort = FetchItemsSort;

export type PayInvoiceIsLoading = IsLoading;

export const enum FetchInvoicesSortTypes {
  AMOUNT_CENTS_ASC = 'AMOUNT_CENTS_ASC',
  AMOUNT_CENTS_DESC = 'AMOUNT_CENTS_DESC',
  ID_DESC = 'ID_DESC',
  ID_ASC = 'ID_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  DUE_DATE_DESC = 'DUE_DATE_DESC',
  DUE_DATE_ASC = 'DUE_DATE_ASC',
  PREPAYMENT_DESC = 'PREPAYMENT_DESC',
  PREPAYMENT_ASC = 'PREPAYMENT_ASC',
  SENT_AT_ASC = 'SENT_AT_ASC',
  SENT_AT_DESC = 'SENT_AT_DESC',
  DRAFT_AT_ASC = 'DRAFT_AT_ASC',
  DRAFT_AT_DESC = 'DRAFT_AT_DESC',
  CREATED_AT_SENT_DRAFT_FIRST = 'CREATED_AT_SENT_DRAFT_FIRST'
}

export const enum FetchGroupedInvoicesSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  AMOUNT_ASC = 'AMOUNT_ASC',
  AMOUNT_DESC = 'AMOUNT_DESC',
  COMPANY_ASC = 'COMPANY_ASC',
  COMPANY_DESC = 'COMPANY_DESC',
  DUE_DATE_ASC = 'DUE_DATE_ASC',
  DUE_DATE_DESC = 'DUE_DATE_DESC',
  ITEM_ASC = 'ITEM_ASC',
  ITEM_DESC = 'ITEM_DESC',
  SHARE_ASC = 'SHARE_ASC',
  SHARE_DESC = 'SHARE_DESC',
  SMART_CONTRACT_NET_ASC = 'SMART_CONTRACT_NET_ASC',
  SMART_CONTRACT_NET_DESC = 'SMART_CONTRACT_NET_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC'
}

export const enum FetchGroupedInvoicesSortableFields {
  CREATED_AT = 'createdAt',
  AMOUNT = 'amount',
  COMPANY = 'company',
  DUE_DATE = 'dueDate',
  ITEM = 'item',
  SHARE = 'share',
  SMART_CONTRACT_NET = 'smartContractNet',
  STATUS = 'status'
}

export const enum FetchInvoicesSortableFields {
  ID = 'id',
  CREATED_AT = 'createdAt',
  DUE_DATE = 'dueDate',
  PAYMENT_DATE = 'paymentDate',
  AMOUNT = 'amount_cents',
  PREPAYMENT = 'prepayment',
  STATUS = 'status',
  RETURN_REASON = 'returnReason'
}

export const enum InvoiceFields {
  ID = 'id',
  CREATED_AT = 'createdAt',
  PAYMENT_DATE = 'paymentDate',
  AMOUNT = 'amount',
  STATUS = 'status'
}

export interface FetchInvoicesFilters {
  nanoId?: AssociationNanoIDsFilter;
  projectNanoId?: NanoIDFilter;
  taskNanoId?: NanoIDFilter;
  companyNanoId?: NanoIDFilter;
  companyContractorNanoId?: NanoIDFilter;
  createdAtGte?: StringFilter;
  createdAtLt?: StringFilter;
  createdAtLte?: StringFilter;
  current?: BooleanFilter;
  status?: InvoiceStatuses | InvoiceStatuses[];
  invoiceType?: InvoiceInvoiceType | InvoiceInvoiceType[];
  overdue?: BooleanFilter;
  projectNanoIdAndTasks?: NanoIDFilter;
  with_primary?: BooleanFilter;
  with_secondary?: BooleanFilter;
  without_primary?: BooleanFilter;
  without_secondary?: BooleanFilter;
}

export interface FetchFinInvoicesFilters {
  companyContractorNanoId?: NanoIdFilterType;
  billingCompanyName?: TextFilterType;
  companyName?: TextFilterType;
  companyNanoId?: NanoIdFilterType;
  clientManagerNanoId?: NanoIdFilterType;
  createdAt?: DateFilterType;
  current?: BooleanFilter;
  dueDate?: DateFilterType;
  generalLedgerId?: IdFilterType;
  id?: IdFilterType;
  invoiceTotal?: FloatFilterType;
  invoiceType?: InvoiceTypeFilter;
  itemName?: TextFilterType;
  journalRecordImportId?: IdFilterType;
  name?: InvoiceNameFilter;
  nanoId?: NanoIdFilterType;
  overdue?: BooleanFilter;
  paymentMethod?: InvoicePaymentMethodFilter;
  paymentProcessing?: BooleanFilter;
  projectName?: TextFilterType;
  projectNanoId?: NanoIdFilterType;
  projectNanoIdAndTaskAndParents?: NanoIdFilterType;
  projectNanoIdAndTasks?: NanoIdFilterType;
  returnReason?: InvoiceReturnReasonFilter;
  status?: InvoiceStatusFilter;
  taskName?: TextFilterType;
  taskNanoId?: NanoIdFilterType;
  thisAndChildrenIdOrName?: StringFilter;
  thisAndChildrenItemName?: TextFilterType;
  thisAndChildrenProjectName?: TextFilterType;
  thisAndChildrenTaskName?: TextFilterType;
  uuid?: UuidFilterType;
  withPrimary?: BooleanFilter;
  withSecondary?: BooleanFilter;
  withoutPrimary?: BooleanFilter;
  withoutSecondary?: BooleanFilter;
}

export interface FetchFinGroupedInvoicesFilters {
  companyNanoId?: NanoIdFilterType;
  createdAt?: DateFilterType;
  status?: InvoiceStatusFilter;
  invoiceType?: InvoiceTypeFilter;
  overdue?: BooleanFilter;
  current?: BooleanFilter;
}

export type InvoicePayerId = UserID;

export const enum InvoiceTypes {
  TRANSFER = 'transfer',
  WITHDRAW = 'withdraw',
  CHARGEBACK = 'chargeback',
  REFUND = 'refund',
  CONTRACTOR = 'contractor',
  REGULAR_NET = 'regular_net',
  REGULAR = 'regular',
  DEPOSIT = 'deposit',
  NONE = 'none',
  ORD_WITHDRAW = 'ord_withdraw'
}

export const enum InvoiceStatuses {
  SENT = 'sent',
  PAID = 'paid',
  OPEN = 'open',
  CANCELED = 'canceled',
  UNCOLLECTIBLE = 'uncollectible',
  VOID = 'void',
  DRAFT = 'draft',
  NONE = 'none',
  PREPAID = 'prepaid',
  PROCESSING = 'processing'
}

export interface InvoicesMetaAmountByStatus {
  canceled: MoneyType;
  draft: MoneyType;
  none: MoneyType;
  open: MoneyType;
  paid: MoneyType;
  sent: MoneyType;
  uncollectible: MoneyType;
  void: MoneyType;
}

export const invoiceStatusTexts = {
  [InvoiceStatuses.DRAFT]: 'Draft',
  [InvoiceStatuses.VOID]: 'Void',
  [InvoiceStatuses.UNCOLLECTIBLE]: 'Uncollectible',
  [InvoiceStatuses.CANCELED]: 'Canceled',
  [InvoiceStatuses.SENT]: 'Sent',
  [InvoiceStatuses.PAID]: 'Paid',
  [InvoiceStatuses.PREPAID]: 'Prepaid',
  [InvoiceStatuses.OPEN]: 'Open',
  [InvoiceStatuses.PROCESSING]: 'Processing'
};

export const invoiceStatusTextStyles = {
  [InvoiceStatuses.DRAFT]: 'text-gray-600 dark:text-gray-600',
  [InvoiceStatuses.VOID]: 'text-red-600 dark:text-red-600',
  [InvoiceStatuses.UNCOLLECTIBLE]: 'text-red-600 dark:text-red-600',
  [InvoiceStatuses.CANCELED]: 'text-red-600 dark:text-red-600',
  [InvoiceStatuses.SENT]: 'text-black dark:text-white',
  [InvoiceStatuses.PAID]: 'text-green-800 dark:text-green-800',
  [InvoiceStatuses.PREPAID]: 'text-green-800 dark:text-green-800',
  [InvoiceStatuses.OPEN]: 'text-yellow-800 dark:text-yellow-800',
  [InvoiceStatuses.PROCESSING]: 'text-orange-800 dark:text-orange-800'
};

export const enum InvoicePaymentMethods {
  WIRE = 'wire',
  CARD = 'card',
  DEPOSIT = 'deposit',
  AV = 'av',
  PFR = 'pfr'
}

export const enum InvoiceReturnReasons {
  CHARGEBACK = 'chargeback',
  REFUND = 'refund',
  RETURN = 'return',
  ERROR = 'error',
  NONE = 'none'
}

export const invoiceReturnReasonTexts = {
  [InvoiceReturnReasons.CHARGEBACK]: 'Chargeback',
  [InvoiceReturnReasons.REFUND]: 'Refund',
  [InvoiceReturnReasons.RETURN]: 'Return',
  [InvoiceReturnReasons.ERROR]: 'Error',
  [InvoiceReturnReasons.NONE]: 'None'
};

export type InvoiceNameFilter = TextFilterType;

export type InvoiceTypeFilter = {
  eq?: InvoiceTypes;
  in?: InvoiceTypes[];
  notEq?: InvoiceTypes;
  notIn?: InvoiceTypes[];
};

export type InvoicePaymentMethodFilter = {
  eq?: AvBillingInfoPaymentMethods;
  in?: AvBillingInfoPaymentMethods[];
  notEq?: AvBillingInfoPaymentMethods;
  notIn?: AvBillingInfoPaymentMethods[];
};

export type InvoiceStatusFilter = {
  eq?: InvoiceStatuses;
  in?: InvoiceStatuses[];
  notEq?: InvoiceStatuses;
  notIn?: InvoiceStatuses[];
};

export type InvoiceReturnReasonFilter = {
  eq?: InvoiceReturnReasons;
  in?: InvoiceReturnReasons[];
  notEq?: InvoiceReturnReasons;
  notIn?: InvoiceReturnReasons[];
};

export type FilterFinInvoicesFunc = (
  nextFilters: FetchFinInvoicesFilters
) => void;

export type ChangeFinInvoicesFiltersFunc = (
  changedFilters: FetchFinInvoicesFilters,
  removeFilters?: string[]
) => void;

export type ClearFinInvoicesFiltersFunc = () => void;

export const enum FetchFinInvoicesFilterFields {
  BILLING_COMPANY_NAME = 'billingCompanyName',
  COMPANY_NAME = 'companyName',
  CLIENT_MANAGER_NANO_ID = 'clientManagerNanoId',
  CREATED_AT = 'createdAt',
  DUE_DATE = 'dueDate',
  INVOICE_ID = 'invoiceId',
  INVOICE_TOTAL = 'invoiceTotal',
  ITEM_NAME = 'itemName',
  NAME = 'name',
  PAYMENT_METHOD = 'paymentMethod',
  PAYMENT_PROCESSING = 'paymentProcessing',
  PROJECT_NAME = 'projectName',
  PROJECT_NANO_ID = 'projectNanoId',
  STATUS = 'status',
  TASK_NAME = 'taskName',
  TASK_NANO_ID = 'taskNanoId',
  THIS_AND_CHILDREN_ID_OR_NAME = 'thisAndChildrenIdOrName',
  THIS_AND_CHILDREN_ITEM_NAME = 'thisAndChildrenItemName',
  THIS_AND_CHILDREN_PROJECT_NAME = 'thisAndChildrenProjectName',
  THIS_AND_CHILDREN_TASK_NAME = 'thisAndChildrenTaskName'
}
