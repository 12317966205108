import { useCallback } from 'react';
import { useQueryClient, QueryKey } from 'react-query';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

type UpdateQueryCacheActionArg<T> = T | ((prevState: T) => T);
type UpdateQueryCacheRollback = () => void;
type UpdateQueryCacheAction<T> = (
  dataOrCb: UpdateQueryCacheActionArg<T>
) => UpdateQueryCacheRollback;

interface ShowQueryUpdateItemCacheOptions {
  fullCacheKey: QueryKey;
  itemKey: string;
}

function useShowQueryUpdateItemCache<DataType, ItemType>({
  fullCacheKey,
  itemKey
}: ShowQueryUpdateItemCacheOptions) {
  const queryClient = useQueryClient();

  const updateItemCache = useCallback<UpdateQueryCacheAction<ItemType>>(
    (dataOrCb) => {
      queryClient.cancelQueries(fullCacheKey);

      const previousData = queryClient.getQueryData<DataType>(fullCacheKey);

      const previousItem: ItemType = get(previousData, itemKey);

      const updatedItem = isFunction(dataOrCb)
        ? previousItem && dataOrCb(previousItem)
        : dataOrCb;

      if (previousData && updatedItem) {
        queryClient.setQueryData<DataType>(fullCacheKey, {
          ...previousData,
          [itemKey]: updatedItem
        });
      }

      return () => {
        if (previousData && updatedItem) {
          queryClient.setQueryData<DataType>(fullCacheKey, previousData);
        }
      };
    },
    [fullCacheKey, queryClient, itemKey]
  );

  return updateItemCache;
}

export default useShowQueryUpdateItemCache;
