import { useEffect, useMemo } from 'react';

import first from 'lodash/first';

import { TeamNanoID } from '../../../teams/teamsTypes';
import { GeneralLedgerGeneralLedgerTypes } from '../../../generalLedgers/generalLedgersTypes';

import {
  FETCH_ACCOUNTS_QUERY,
  FetchAccountsQueryResponse
} from '../../queries/fetchAccounts.query';
import {
  FETCH_GENERAL_LEDGERS_QUERY,
  FetchGeneralLedgersQueryResponse
} from '../../../generalLedgers/queries/fetchGeneralLedgers.query';

import { useFinPaginatedAccounts } from '../useFinPaginatedAccounts';
import { useFinPaginatedGeneralLedgers } from '../../../generalLedgers/hooks/useFinPaginatedGeneralLedgers';
import { usePreviousValue } from '../../../../common/hooks/usePreviousValue';

import { AccountCache } from '../../AccountCache';
import { GeneralLedgerCache } from '../../../generalLedgers/GeneralLedgerCache';

import {
  CASH_ACCOUNT_TYPE_CHART_NUMBER,
  DEBT_ACCOUNT_TYPE_CHART_NUMBER,
  REVENUE_REVERSE_DEBT_ACCOUNT_TYPE_CHART_NUMBER
} from '../../accountsConstants';
import filter from 'lodash/filter';
import { AccountBalance } from '../../accountsTypes';

type GetBalanceOptions = {
  companyCashAccount: { balance: AccountBalance };
  companyRevenueReverseDebtAccount: { balance: AccountBalance };
  companyDebtAccount: { balance: AccountBalance };
};

const getBalance = ({
  companyCashAccount,
  companyRevenueReverseDebtAccount,
  companyDebtAccount
}: GetBalanceOptions) =>
  (companyCashAccount?.balance || 0) -
  (companyRevenueReverseDebtAccount?.balance || 0) -
  (companyDebtAccount?.balance || 0);

interface CompanyBalanceAccountsProps {
  companyNanoId: TeamNanoID;
}

function useCompanyBalanceAccounts({
  companyNanoId
}: CompanyBalanceAccountsProps) {
  const prevCompanyNanoId = usePreviousValue(companyNanoId);

  const {
    generalLedgers,
    generalLedgersFetched,
    generalLedgersError,
    changeGeneralLedgersFilters
  } = useFinPaginatedGeneralLedgers<FetchGeneralLedgersQueryResponse>({
    cacheKey:
      GeneralLedgerCache.companyDefaultGeneralLedgersCacheKey(companyNanoId),
    query: FETCH_GENERAL_LEDGERS_QUERY,
    initialFilters: {
      generalLedgerType: { eq: GeneralLedgerGeneralLedgerTypes.DEFAULT },
      companyNanoId: { eq: companyNanoId }
    },
    options: {
      enabled: !!companyNanoId,
      enabledPlaceholder: !!companyNanoId,
      withoutPrefetch: true
    }
  });

  const prevGeneralLedgersId = usePreviousValue(first(generalLedgers)?.id);

  const {
    accounts,
    accountsErrorMessage,
    accountsFetched,
    changeAccountsFilters
  } = useFinPaginatedAccounts<FetchAccountsQueryResponse>({
    query: FETCH_ACCOUNTS_QUERY,
    cacheKey: AccountCache.companyBalanceAccountsCacheKey(companyNanoId),
    initialFilters: {
      accountTypeChartNumber: {
        in: [
          CASH_ACCOUNT_TYPE_CHART_NUMBER,
          REVENUE_REVERSE_DEBT_ACCOUNT_TYPE_CHART_NUMBER,
          DEBT_ACCOUNT_TYPE_CHART_NUMBER
        ]
      },
      companyNanoId: { eq: companyNanoId },
      generalLedgerId: generalLedgers
        ? { eq: first(generalLedgers)?.id }
        : undefined
    },
    options: {
      enabled: !!companyNanoId && generalLedgersFetched,
      enabledPlaceholder: !!companyNanoId && generalLedgersFetched,
      withoutPrefetch: true
    }
  });

  useEffect(() => {
    if (prevCompanyNanoId !== companyNanoId) {
      changeAccountsFilters({
        companyNanoId: { eq: companyNanoId }
      });
      changeGeneralLedgersFilters({
        companyNanoId: { eq: companyNanoId }
      });
    }
  }, [
    changeAccountsFilters,
    changeGeneralLedgersFilters,
    companyNanoId,
    prevCompanyNanoId
  ]);

  useEffect(() => {
    if (
      prevGeneralLedgersId !== first(generalLedgers)?.id &&
      first(generalLedgers)?.id
    ) {
      changeAccountsFilters({
        generalLedgerId: { eq: first(generalLedgers)?.id }
      });
    }
  }, [
    changeAccountsFilters,
    generalLedgers,
    prevCompanyNanoId,
    prevGeneralLedgersId
  ]);

  const companyCashAccount =
    first(
      filter(
        accounts,
        (account) =>
          account.accountType.chartNumber === CASH_ACCOUNT_TYPE_CHART_NUMBER
      )
    ) || null;

  const companyRevenueReverseDebtAccount =
    first(
      filter(
        accounts,
        (account) =>
          account.accountType.chartNumber ===
          REVENUE_REVERSE_DEBT_ACCOUNT_TYPE_CHART_NUMBER
      )
    ) || null;

  const companyDebtAccount =
    first(
      filter(
        accounts,
        (account) =>
          account.accountType.chartNumber === DEBT_ACCOUNT_TYPE_CHART_NUMBER
      )
    ) || null;

  const companyBalance = useMemo(
    () =>
      getBalance({
        companyCashAccount,
        companyRevenueReverseDebtAccount,
        companyDebtAccount
      }),
    [companyCashAccount, companyDebtAccount, companyRevenueReverseDebtAccount]
  );

  return {
    companyBalance,
    companyCashAccount,
    companyRevenueReverseDebtAccount,
    companyDebtAccount,
    companyBalanceAccountsErrorMessage:
      accountsErrorMessage || generalLedgersError,
    companyBalanceAccountsFetched: accountsFetched
  };
}

export default useCompanyBalanceAccounts;
