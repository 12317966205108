import {
  DownloadFile,
  DownloadName
} from '../../main/downloads/downloadsTypes';

export interface DownloadFileOptions {
  name: DownloadName;
  file: DownloadFile;
}

async function downloadFile(downloadFile: DownloadFileOptions) {
  if (typeof window === 'undefined' || !window?.document?.body) {
    return null;
  }

  // const link = document.createElement('a');
  // link.href = downloadFile.file;
  // link.download = downloadFile.name;
  // document.body.appendChild(link);
  // link.addEventListener('click', (e: MouseEvent) => {
  //   e.stopPropagation();
  // });
  // link.click();
  // document.body.removeChild(link);

  const response = await fetch(downloadFile.file);
  const blob = await response.blob();
  const fileName = downloadFile.name;
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export default downloadFile;
