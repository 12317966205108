import { TeamNanoID } from '../teams/teamsTypes';
import { FetchAccountsSortTypes } from './accountsTypes';

export const INITIAL_ACCOUNTS_FILTERS = {};
export const INITIAL_ACCOUNTS_PAGE = 1;
export const INITIAL_ACCOUNTS_SORT = [FetchAccountsSortTypes.CREATED_AT_DESC];
export const INITIAL_ACCOUNTS_LIMIT = 50;

export const CASH_ACCOUNT_TYPE_CHART_NUMBER = '1.1.1';
export const REVENUE_REVERSE_DEBT_ACCOUNT_TYPE_CHART_NUMBER = '2.4.3';
export const DEBT_ACCOUNT_TYPE_CHART_NUMBER = '2.5.0';

export const avCompanyNanoId = '7VLCHWKW' as TeamNanoID;

export const internalAccountsTypeChartNumbers = [
  '5.1.3',
  '5.1.7',
  '5.2.1',
  '5.2.2.1',
  '5.2.2.2',
  '5.2.2.3',
  '6.2.8'
];

export const enum AccountIncomeStatementAccountChartNumbers {
  CHART_NUMBER_4_1_2 = '4.1.2',
  CHART_NUMBER_4_3_2 = '4.3.2',
  CHART_NUMBER_5_0_0 = '5.0.0',
  CHART_NUMBER_4_5_1 = '4.5.1',
  CHART_NUMBER_5_2_7 = '5.2.7',
  CHART_NUMBER_3_1_1 = '3.1.1',
  CHART_NUMBER_5_2_1 = '5.2.1'
}

export const accountIncomeStatementAccountTypeChartNumbers = [
  AccountIncomeStatementAccountChartNumbers.CHART_NUMBER_4_1_2,
  AccountIncomeStatementAccountChartNumbers.CHART_NUMBER_4_3_2,
  AccountIncomeStatementAccountChartNumbers.CHART_NUMBER_5_0_0,
  AccountIncomeStatementAccountChartNumbers.CHART_NUMBER_4_5_1,
  AccountIncomeStatementAccountChartNumbers.CHART_NUMBER_5_2_7,
  AccountIncomeStatementAccountChartNumbers.CHART_NUMBER_3_1_1,
  AccountIncomeStatementAccountChartNumbers.CHART_NUMBER_5_2_1
];

export enum AccountPageTabs {
  JOURNAL_RECORDS = 'Journal Records',
  ACCOUNT_BALANCES = 'Account Balances'
}

export enum AccountsPermissions {
  READ_ACCOUNT_SHOW_PAGE = 'read_account_show_page',
  READ_PAYMENTS_BALANCE_SHEET_MENU_INDEX_LINK = 'read_payments_balance_sheet_menu_index_link',
  READ_PAYMENTS_BALANCE_SHEET_INDEX_PAGE = 'read_payments_balance_sheet_index_page',
  READ_PAYMENTS_BALANCE_SHEET_RECALCULATE_BALANCES_BUTTON = 'read_payments_balance_sheet_recalculate_balances_button',
  READ_PAYMENTS_ACCOUNTS_ASSETS_TABLE = 'read_payments_accounts_assets_table',
  READ_PAYMENTS_ACCOUNTS_EQUITY_TABLE = 'read_payments_accounts_equity_table',
  READ_PAYMENTS_ACCOUNTS_LIABILITY_TABLE = 'read_payments_accounts_liability_table',
  READ_PAYMENTS_ACCOUNTS_PROVISIONS_TABLE = 'read_payments_accounts_provisions_table',
  READ_PAYMENTS_BALANCE_SHEET_INCOME_STATEMENT_SECTION = 'read_payments_balance_sheet_income_statement_section',
  READ_PAYMENTS_ACCOUNTS_HIDE_ZERO_BALANCE_FILTER = 'read_payments_accounts_hide_zero_balance_filter',
  READ_PAYMENTS_ACCOUNTS_TYPE_FILTER = 'read_payments_accounts_type_filter',
  READ_ACCOUNTS_TABLE_SELECTED_FIELD = 'read_accounts_table_selected_field',
  READ_ACCOUNTS_TABLE_DROPDOWN_FIELD = 'read_accounts_table_dropdown_field',
  READ_ACCOUNTS_TABLE_BALANCE_FIELD = 'read_accounts_table_balance_field',
  READ_ACCOUNTS_TABLE_LAST_TRANSACTION_FIELD = 'read_accounts_table_last_transaction_field',
  READ_ACCOUNTS_TABLE_NUMBER_FIELD = 'read_accounts_table_number_field',
  READ_ACCOUNTS_TABLE_NUMBER_FIELD_LINK = 'read_accounts_table_number_field_link',
  READ_ACCOUNTS_TABLE_NAME_FIELD = 'read_accounts_table_name_field',
  READ_ACCOUNTS_TABLE_TRANSACTIONS_LINK = 'read_accounts_table_transactions_link',
  READ_ACCOUNT_BALANCES_PAGE = 'read_account_balances_page',
  READ_ACCOUNT_BALANCE_PAGE = 'read_account_balance_page'
}
