import { CreatedAt, DateType, ID, NanoID, UUID } from './fields';

export type AssociationIDFilter = ID;
export type AssociationIDsFilter = ID[];
export type AssociationUUIDsFilter = UUID[];
export type AssociationNanoIDsFilter = NanoID[];
export type UUIDFilter = UUID;
export type UUIDsFilter = UUID[];
export type NanoIDFilter = NanoID;
export type IDFilter = ID;
export type IDsFilter = IDFilter[];
export type BooleanFilter = boolean;
export type CreatedAtFilter = CreatedAt;
export type StringArrayFilter = string[];
export type StringFilter = string;
export type TagIDsFilter = ID[];

export type TextFilterType = {
  eq?: string;
  ilike?: string;
  ilikeEnd?: string;
  ilikeStart?: string;
  in?: string[];
  isNotNull?: boolean;
  isNull?: boolean;
  like?: string;
  notEq?: string;
  likeEnd?: string;
  likeStart?: string;
  notIn?: string[];
  search?: string;
};

export type IdFilterType = {
  eq?: ID;
  gt?: ID;
  gte?: ID;
  in?: ID[];
  isNotNull?: boolean;
  isNull?: boolean;
  lt?: ID;
  lte?: ID;
  notIn?: ID[];
};

export type PositiveNanoIdFilterType = {
  eq?: ID;
  in?: ID[];
};

export type NanoIdFilterType = {
  eq?: ID;
  in?: ID[];
  isNotNull?: boolean;
  isNull?: boolean;
  notEq?: ID;
  notIn?: ID[];
};

export type NumberFilterType = {
  eq?: number;
  gt?: number;
  gte?: number;
  lt?: number;
  lte?: number;
};

export type IntegerFilterType = {
  eq?: number;
  gt?: number;
  gte?: number;
  in?: number[];
  isNotNull?: boolean;
  isNull?: boolean;
  lt?: number;
  lte?: number;
  notEq?: number;
};

export type FloatFilterType = {
  eq?: number;
  gt?: number;
  gte?: number;
  isNull?: boolean;
  lt?: number;
  lte?: number;
  notEq?: number;
};

export type DateFilterType = {
  eq?: DateType;
  gt?: DateType;
  gte?: DateType;
  isNotNull?: boolean;
  isNull?: boolean;
  lt?: DateType;
  lte?: DateType;
  notEq?: DateType;
};

export type UuidFilterType = {
  eq?: UUID;
  in?: UUID[];
  isNotNull?: boolean;
  isNull?: boolean;
  notEq?: UUID;
  notIn?: UUID[];
};

export type IdOrNanoFilterType = {
  eq?: ID;
  in?: ID[];
};

export const enum FiltersViewGroups {
  TASKS = 'tasks',
  TASKS_USER_GROUPS = 'tasks_user_groups',
  PROJECT_TASKS = 'project_tasks',
  PROJECTS = 'projects'
}
