import { UUID } from '../../../types';

import {
  FetchCurrentUserCacheKey,
  FetchCurrentUserGqlQuery
} from '../../authTypes';

// import { uploadsClient } from '../../../main/common/hooks/base/reactQuery/graphQLClients';

import { useFinShowQuery } from '../../../main/common/hooks/base/reactQuery/useFinShowQuery';
import { ShowQueryOpts } from '../../../main/common/hooks/base/reactQuery/useShowQuery';

interface CurrentUserResponse<ItemType> {
  currentUser: ItemType | null;
}

interface CurrentUserOptions<ItemType> {
  cacheKey: FetchCurrentUserCacheKey;
  query: FetchCurrentUserGqlQuery;
  options?: ShowQueryOpts<CurrentUserResponse<ItemType>>;
}

const itemKey = 'currentUser';

function useFetchFinCurrentUser<ItemType>({
  cacheKey,
  query,
  options = {}
}: CurrentUserOptions<ItemType>) {
  const { item, itemError, itemFetched, itemLoading } = useFinShowQuery<
    CurrentUserResponse<ItemType>,
    ItemType
  >({
    cacheKey,
    itemKey,
    query,
    uuid: 'currentUser' as UUID,
    options: {
      ...options,
      onSuccess: (data) => {
        options?.onSuccess(data);

        // uploadsClient
        //   .request<CurrentUserResponse<ItemType>>(query)
        //   .then((uploadsResponse) =>
        //     console.log('uploadsClient', { uploadsResponse }, uploadsResponse)
        //   )
        //   .catch((err) => console.log('uploadsClient', { err }, err));
      }
    }
  });

  return {
    currentUser: item,
    currentUserError: itemError,
    currentUserFetched: itemFetched,
    currentUserLoading: itemLoading
  };
}

export default useFetchFinCurrentUser;
