import React, { ReactNode, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { sendGTMEvent } from '@next/third-parties/google';
import includes from 'lodash/includes';
import startsWith from 'lodash/startsWith';
import { TeamNanoID } from '../../../main/teams/teamsTypes';
import { UserNanoID } from '../../../main/users/usersTypes';

import { useAuth } from '../../../auth/hooks/useAuth';
import { useNonTpvLastPath } from '../../hooks/useNonTpvLastPath';

import { AuthAppLayout } from '../AuthAppLayout';
import { AuthAppLayoutWithCurrentUser } from '../AuthAppLayoutWithCurrentUser';
import { CompanyViewAppLayout } from '../CompanyViewAppLayout';
import { MainAppLayout } from '../MainAppLayout';

import { isCompanyViewRoute } from '../../utils/isCompanyViewRoute';

import { AuthPath } from '../../../auth/AuthPath';

import { GTM_DATA_LAYER_NAME, IS_PRODUCTION } from '../../../config';

interface AppLayoutProps {
  children: ReactNode;
}

function AppLayout({ children }: AppLayoutProps) {
  const auth = useAuth();
  const router = useRouter();
  const nonTpvLastPath = useNonTpvLastPath(router);

  const handleLogoutUser = useCallback<() => void>(
    () => router.push(AuthPath.login()),
    [router]
  );

  const isTermsRoute = /^\/terms/.test(router.pathname);

  useEffect(() => {
    if (IS_PRODUCTION && auth?.authFetched && auth?.isAuthenticated) {
      const dataLayerArgs = {
        userId: auth.currentUser?.id,
        dataLayerName: GTM_DATA_LAYER_NAME
      };

      sendGTMEvent(dataLayerArgs);
    }
  }, [auth?.authFetched, auth?.isAuthenticated, auth.currentUser?.id]);

  if (
    startsWith(router.pathname, AuthPath.inviteTeamMembers()) ||
    startsWith(router.pathname, AuthPath.businessDescription()) ||
    startsWith(router.pathname, AuthPath.createProject())
  ) {
    return (
      <AuthAppLayoutWithCurrentUser>{children}</AuthAppLayoutWithCurrentUser>
    );
  }

  if (isTermsRoute) {
    return <AuthAppLayout>{children}</AuthAppLayout>;
  }

  if (
    includes(router.pathname, AuthPath.base()) ||
    includes(router.pathname, AuthPath.registerShort())
  ) {
    return <AuthAppLayout>{children}</AuthAppLayout>;
  }

  if (isCompanyViewRoute(router)) {
    return (
      <CompanyViewAppLayout
        companyNanoId={router.query.slug as TeamNanoID}
        userNanoId={router.query.userSlug as UserNanoID}
        nonTpvLastPath={nonTpvLastPath}
      >
        {children}
      </CompanyViewAppLayout>
    );
  }

  return (
    <MainAppLayout onLogoutUser={handleLogoutUser}>{children}</MainAppLayout>
  );
}

export default AppLayout;
