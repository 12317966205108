export default {
  addIteration: 'Add iteration',
  addShare: 'Add share',
  av: 'AV',
  avGetsFixedShare: 'AV gets fixed share',
  avGetsRemainderAfterAllOtherParticipants:
    'AV gets remainder after all other participants',
  avMinimumShare: 'AV minimum share',
  avShareScheme: 'AV Share Scheme',
  archivizer: 'Archivizer',
  areYuSureYouWantToCancelTheSmartContract:
    'Are you sure you want to cancel the smart contract?',
  areYuSureYouWantToClearAllSmartContractShares:
    'Are you sure you want to clear all smart contract shares?',
  areYuSureYouWantToDeleteTheSmartContractAutoCheckInRule:
    'Are you sure you want to delete the smart contract auto check in rule?',
  areYuSureYouWantToDeleteTheCompanySmartContractTemplate:
    'Are you sure you want to delete the company smart contract template?',
  amount: 'Amount',
  allSmartContracts: 'All smart contracts',
  author: 'Author',
  autoPaymentSc: 'Auto payment SC',
  basicSmartContract: 'Basic smart contract',
  bound: 'Bound',
  cancel: 'Cancel',
  canceled: 'Canceled',
  checkIn: 'Check in',
  checkOut: 'Check out',
  created: 'Created',
  createTemplate: 'Create template',
  createSmartContractTemplate: 'Create smart contract template',
  createRule: 'Create rule',
  createSmartContractAutoCheckInRule:
    'Create smart contract auto check in rule',
  createCompanySmartContractTemplate: 'Create company smart contract template',
  closed: 'Closed',
  clearAllShares: 'Clear all shares',
  changeShare: 'Change share',
  checkTheAvailabilityOfAllSmartContractRules:
    'Check the availability of all smart contract rules',
  contractorRule: 'Contractor rule',
  delete: 'Delete',
  deleteIteration: 'Delete iteration',
  dueDate: 'Due date',
  draftSmartContract: 'Draft smart contract',
  draftSmartContracts: 'Draft smart contracts',
  editSmartContractAutoCheckInRule: 'Edit smart contract auto check in rule',
  editCompanySmartContractTemplate: 'Edit company smart contract template',
  events: 'Smart contract events',
  fees: 'Fees',
  forBasicWorkflow: 'For basic workflow',
  forPaymentsFromAv: 'For payments from AV',
  forPaymentsFromPfr: 'For payments from PFR',
  fixed: 'Fixed',
  finalIteration: 'FI',
  finalIterationTotal: 'FI total',
  id: 'ID',
  internalSmartContract: 'Internal smart contract',
  internalSmartContractIsUsedForPaymentsFromPfrPfbdOrDirectlyFromArchivizer:
    'Internal smart contract is used for payments from PFR, PFBD, or directly from Archivizer',
  invoices: 'Invoices',
  iteration: 'Iteration',
  joinTheClients: 'Join the clients',
  joinTheWorkers: 'Join the workers',
  holdRate: 'Hold rate',
  label: 'Label',
  name: 'Name',
  net: 'Net',
  netAmount: 'Net amount',
  noAvShareInSmartContract: 'No AV share in smart-contract',
  noParticipantsYet: 'No participants yet',
  openSmartContract: 'Open smart contract',
  opened: 'Opened',
  participants: 'Participants',
  paid: 'Paid',
  payer: 'Payer',
  payFromAV: 'Pay from AV',
  payFromPFR: 'Pay from PFR',
  payFromPFBD: 'Pay from PFBD',
  plural: 'Smart contracts',
  pfbd: 'PFBD',
  pfr: 'PFR',
  project: 'Project',
  process: 'Process',
  postponed: 'Postponed',
  provisionForRevision: 'Provision for revision',
  provisionForBadDebt: 'Provision for bad debt',
  revenueSharing: 'Revenue sharing',
  revision: 'Revision',
  revisionSmartContract: 'Revision smart contract',
  residual: 'Residual',
  removeFromContract: 'Remove from contract',
  removeShare: 'Remove share',
  renewal: 'Renewal',
  role: 'role',
  singular: 'Smart contract',
  scf: 'SCF',
  scTat: 'SC TAT',
  smartContractFee: 'Smart contract fee',
  smartContractFees: 'Smart contract fees',
  smartContractType: 'Smart contract type',
  smartContractTotal: 'Smart contract total',
  smartContractTemplates: 'Smart contract templates',
  smartContractTemplate: 'Smart contract template',
  share: 'Share',
  status: 'Status',
  statuses: {
    open: 'Open',
    canceled: 'Canceled',
    draft: 'Draft',
    done: 'Done'
  },
  statusLabels: {
    all: 'All',
    open: 'Open',
    canceled: 'Canceled',
    closed: 'Closed',
    draft: 'Draft',
    done: 'Done'
  },
  task: 'Task',
  totalAmount: 'Total amount',
  totalShareExceeds100: 'Sum of shares on this iteration exceeds 100%',
  terms: 'Terms',
  transactionFee: 'Transaction fee',
  trx: 'TRX',
  value: 'Value',
  userId: 'User ID',
  usersFromTheListBelowWillBeAutomaticallyCheckedIntoAllNewSmartContractsInWhichParticipate:
    'Users from the list below will be automatically checked into all new smart contracts in which they participate',
  willBeAutomaticallyCheckedIntoAllNewSmartContractsInWhichParticipateUsersFromTheListBelow:
    'will be automatically checked into all new smart contracts in which participate users from the list below.',
  willBeAutomaticallyCheckedForAllNewSmartContractsCreatedForClientsFromTheListBelow:
    'will be automatically checked for all new smart contracts created for clients from the list below.'
};
