import React, { Fragment } from 'react';

import { I18nText, IsDisabled, RegisterFormFieldType } from '../../../../types';

import { Form } from '../../../../helpers/Form';
import { InputField } from '../../../../helpers/FormFields/InputField';
import { TextAreaField } from '../../../../helpers/FormFields/TextAreaField';
import { Translate } from '../../../../helpers/Translate';

import {
  formsFields,
  messagesKeys,
  stringsKeys
} from '../../../../locales/keys';

interface FeedBackFormProps {
  disabled: IsDisabled;
  form: string;
  isAuthenticated: boolean;
  registerBody: RegisterFormFieldType<HTMLTextAreaElement>;
  registerEmail: RegisterFormFieldType<HTMLInputElement>;
  registerName: RegisterFormFieldType<HTMLInputElement>;
  registerSubject: RegisterFormFieldType<HTMLInputElement>;
  validationErrorBody: string;
  validationErrorEmail: string;
  validationErrorName: string;
  validationErrorSubject: string;
  warningI18nText?: I18nText;
}

function FeedBackForm({
  disabled,
  form,
  isAuthenticated,
  registerBody,
  registerEmail,
  registerName,
  registerSubject,
  validationErrorBody,
  validationErrorEmail,
  validationErrorName,
  validationErrorSubject,
  warningI18nText
}: FeedBackFormProps) {
  return (
    <Form id={form}>
      <div className="p-4">
        <p className="text-center">
          <Translate id={stringsKeys.weAreOnline} />
          <br />
          <Translate id={stringsKeys.weAreReadyToHelp} />
        </p>
      </div>

      {warningI18nText && (
        <div className="p-4">
          <div className="bg-red-300/30 dark:bg-red-600/30 p-2 rounded-md">
            <p className="text-center">
              <Translate id={warningI18nText} />
            </p>
          </div>
        </div>
      )}

      <div className="p-4">
        <div className="flex flex-col gap-4">
          {isAuthenticated ? null : (
            <Fragment>
              <InputField
                disabled={disabled}
                error={validationErrorName}
                i18nLabel={formsFields.name}
                inputWrapperClassName="relative mt-1"
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                inputClassName="basic-input mb-2"
                name={registerName.name}
                onChange={registerName.onChange}
                ref={registerName.ref}
                type="text"
              />
              <InputField
                disabled={disabled}
                error={validationErrorEmail}
                i18nLabel={formsFields.email}
                inputWrapperClassName="relative mt-1"
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                inputClassName="basic-input mb-2"
                name={registerEmail.name}
                onChange={registerEmail.onChange}
                ref={registerEmail.ref}
                type="text"
              />
            </Fragment>
          )}

          <InputField
            disabled={disabled}
            error={validationErrorSubject}
            i18nLabel={formsFields.subject}
            inputWrapperClassName="relative mt-1"
            labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
            inputClassName="basic-input"
            name={registerSubject.name}
            onChange={registerSubject.onChange}
            ref={registerSubject.ref}
            type="text"
          />

          <TextAreaField
            disabled={disabled}
            error={validationErrorBody}
            i18nLabel={messagesKeys.singular}
            name={registerBody.name}
            ref={registerBody.ref}
            onChange={registerBody.onChange}
            textAreaClassName="basic-input"
            textAreaWrapperClassName="relative mt-1 flex flex-1 focus-within:ring-4 focus-within:ring-blue-300 overflow-hidden rounded-md self-stretch"
            className="w-full"
            rows={3}
          />
        </div>
      </div>
    </Form>
  );
}

export default FeedBackForm;
