import {
  ID,
  NanoID,
  UUID,
  CreateItemCacheKeys,
  UpdateItemCacheKeys,
  FetchItemsCacheKey,
  FetchItemsFilters,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsSort,
  MoneyType
} from '../../types';

import { InvoiceID } from '../invoices/invoicesTypes';
import { UserID } from '../users/usersTypes';

export const enum FetchPayoneerV4PayoutsSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC'
}

export const enum PayoneerV4PayoutProgramKeys {
  MASS_PAYOUTS_1 = 'mass_payouts_1',
  MASS_PAYOUTS_2 = 'mass_payouts_2',
  MASS_PAYOUTS_3 = 'mass_payouts_3',
  MASS_PAYOUTS_4 = 'mass_payouts_4',
  MASS_PAYOUTS_5 = 'mass_payouts_5',
  MASS_PAYOUTS_6 = 'mass_payouts_6',
  MASS_PAYOUTS_7 = 'mass_payouts_7'
}

export type PayoneerV4PayoutID = ID;
export type PayoneerV4PayoutUUID = UUID;
export type PayoneerV4PayoutNanoID = NanoID;
export type PayoneerV4PayoutInvoiceID = InvoiceID;
export type PayoneerV4PayoutAmount = MoneyType;
export type PayoneerV4PayoutDescription = string;
export type PayoneerV4PayoutPayeeID = UserID;
export type PayoneerV4PayoutProgramKey = PayoneerV4PayoutProgramKeys;

export type FetchPayoneerV4PayoutsCacheKey = FetchItemsCacheKey;
export type FetchPayoneerV4PayoutsFilters = FetchItemsFilters;
export type FetchPayoneerV4PayoutsLimit = FetchItemsLimit;
export type FetchPayoneerV4PayoutsPage = FetchItemsPage;
export type FetchPayoneerV4PayoutsSort = FetchItemsSort;

export type CreatePayoneerV4PayoutCacheKeys = CreateItemCacheKeys;
export type PerformRequestPayoneerV4PayoutCacheKeys = UpdateItemCacheKeys;
export type FetchStatusPayoneerV4PayoutCacheKeys = UpdateItemCacheKeys;
