import { ProjectID, ProjectNanoID } from './projectsTypes';
import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';
import { TaskNanoID } from '../tasks/tasksTypes';
import { TeamNanoID } from '../teams/teamsTypes';
import { UserNanoID } from '../users/usersTypes';
import { WhiteboardStorageBackupID } from '../whiteboardStorageBackups/whiteboardStorageBackupsTypes';

import { CRM_SMASHING_CGI_URL } from '../../config';

const basePath = '/projects';

export class ProjectPath {
  static index() {
    return basePath;
  }

  static compactIndex() {
    return `${basePath}/compact`;
  }

  static compactMyProjects() {
    return `${basePath}/compact/filters/my-projects`;
  }

  static compactMyResults() {
    return `${this.compactMyProjects()}/results`;
  }

  static compactResults() {
    return `${basePath}/compact/results`;
  }

  static compactResultsFilters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/compact/filters/${searchFilterNanoId}/results`;
  }

  static show(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}`;
  }

  static brief(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/brief`;
  }

  static importTasks(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/import-tasks`;
  }

  static burning() {
    return `${basePath}/filters/burning`;
  }

  static evaluations() {
    return `${basePath}/evaluations`;
  }

  static favorite() {
    return `${basePath}/filters/favorite`;
  }

  static myProjects() {
    return `${basePath}/filters/my-projects`;
  }

  static invitations() {
    return `${basePath}/invitations`;
  }

  static compactInvitations() {
    return `${basePath}/compact/invitations`;
  }

  static createInTeam() {
    return `${basePath}/create`;
  }

  static createTasks(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/create-tasks`;
  }

  static invoices() {
    return `${basePath}/invoices`;
  }

  static userProjects($userNanoId: UserNanoID) {
    return `${basePath}/filters/user/${$userNanoId}`;
  }

  static filters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/filters/${searchFilterNanoId}`;
  }

  static compactFilters(searchFilterNanoId: SearchFilterNanoID) {
    return `${basePath}/compact/filters/${searchFilterNanoId}`;
  }

  static accept(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/accept`;
  }

  static messages(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/messages`;
  }

  static depositInvoices(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/deposit-invoices`;
  }

  static messagesById(projectId: ProjectID) {
    return `${basePath}/${projectId}/messages`;
  }

  static taskAttachments(projectNanoId: ProjectNanoID, taskNanoId: TaskNanoID) {
    return `${basePath}/${projectNanoId}/tasks/${taskNanoId}/attachments`;
  }

  static taskFiles(projectNanoId: ProjectNanoID, taskNanoId: TaskNanoID) {
    return `${basePath}/${projectNanoId}/tasks/${taskNanoId}/files`;
  }

  static taskImages(projectNanoId: ProjectNanoID, taskNanoId: TaskNanoID) {
    return `${basePath}/${projectNanoId}/tasks/${taskNanoId}/images`;
  }

  static taskSourceFiles(projectNanoId: ProjectNanoID, taskNanoId: TaskNanoID) {
    return `${basePath}/${projectNanoId}/tasks/${taskNanoId}/source-files`;
  }

  static taskLifestyles(projectNanoId: ProjectNanoID, taskNanoId: TaskNanoID) {
    return `${basePath}/${projectNanoId}/tasks/${taskNanoId}/lifestyles`;
  }

  static taskProducts(projectNanoId: ProjectNanoID, taskNanoId: TaskNanoID) {
    return `${basePath}/${projectNanoId}/tasks/${taskNanoId}/products`;
  }

  static taskMaterials(projectNanoId: ProjectNanoID, taskNanoId: TaskNanoID) {
    return `${basePath}/${projectNanoId}/tasks/${taskNanoId}/materials`;
  }

  static attachments(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/attachments`;
  }

  static files(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/files`;
  }

  static images(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/images`;
  }

  static results(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/results`;
  }

  static lifestyles(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/lifestyles`;
  }

  static products(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/products`;
  }

  static materials(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/materials`;
  }

  static team(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/team`;
  }

  static compactTeamProjects(teamNanoId: TeamNanoID) {
    return `${basePath}/compact/filters/company/${teamNanoId}`;
  }

  static compactTeamResults(teamNanoId: TeamNanoID) {
    return `${this.compactTeamProjects(teamNanoId)}/results`;
  }

  static transactions(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/transactions`;
  }

  static invoicesByNanoId(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/invoices`;
  }

  static activity(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/activity`;
  }

  static batchOperations(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/batch-operations`;
  }

  static showSmashingCgi(projectNanoId: ProjectNanoID) {
    return `${CRM_SMASHING_CGI_URL}${basePath}/${projectNanoId}`;
  }

  static payments(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/payments`;
  }

  static proforma(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/proforma`;
  }

  static proformaV1(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/proforma-v1`;
  }

  static proformaV2(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/proforma-v2`;
  }

  static journalRecords(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/journal-records`;
  }

  static whiteboard(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/whiteboard`;
  }

  static whiteboardBackups(projectNanoId: ProjectNanoID) {
    return `${basePath}/${projectNanoId}/whiteboard-backups`;
  }

  static whiteboardBackup(
    projectNanoId: ProjectNanoID,
    whiteboardBackupId: WhiteboardStorageBackupID
  ) {
    return `${basePath}/${projectNanoId}/whiteboard-backups/${whiteboardBackupId}`;
  }
}
