import { createContext, ReactNode, useContext, useEffect } from 'react';
import { init as initAmplitude, track } from '@amplitude/analytics-browser';
import { init as initSessionReplay } from '@amplitude/session-replay-browser';
import {
  BaseEvent,
  EventOptions
} from '@amplitude/analytics-types/lib/esm/base-event';

import { AMPLITUDE_API_KEY } from '../../../apiConfig';

const AmplitudeAnalyticsContext = createContext({
  track: (
    event: string | BaseEvent,
    eventProperties?: Record<string, any> | undefined,
    eventOptions?: EventOptions | undefined
  ) => {
    console.log('error: track method should be initialized');
  }
});

interface AmplitudeAnalyticsProviderProps {
  children: ReactNode;
}

export function AmplitudeAnalyticsProvider({
  children
}: AmplitudeAnalyticsProviderProps) {
  useEffect(() => {
    AMPLITUDE_API_KEY &&
      initAmplitude(AMPLITUDE_API_KEY, {
        serverZone: 'EU',
        autocapture: {
          elementInteractions: true,
          sessions: true,
          pageViews: true
        }
      });

    AMPLITUDE_API_KEY &&
      initSessionReplay(AMPLITUDE_API_KEY, { sampleRate: 1, serverZone: 'EU' });
  }, []);

  const amplitudeAnalyticsContext = { track };

  return (
    <AmplitudeAnalyticsContext.Provider value={amplitudeAnalyticsContext}>
      {children}
    </AmplitudeAnalyticsContext.Provider>
  );
}

export const useAmplitudeAnalytics = () => {
  return useContext(AmplitudeAnalyticsContext);
};
