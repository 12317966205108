import { gql } from 'graphql-request';

export const FETCH_FIN_TASK_MEMBER_INVITES_TOTAL_COUNT_QUERY = gql`
  query FinTaskMemberInvitesTotalCountQuery(
    $filters: TaskMemberInvitesFilters
    $sort: [TaskMemberInvitesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    taskMemberInvites(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
