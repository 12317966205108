import { useCallback, useRef, useState } from 'react';
import get from 'lodash/get';
import { ClassName } from '../../../../types';

export interface ModalOptions {
  afterSubmit?: () => void | Promise<void>;
  onClose?: () => void;
  onOpen?: () => void;
  onSubmit?: () => Promise<unknown>;
  onToggle?: (isOpen: boolean) => void;
  preventModalClose?: boolean;
  isModalOpen?: boolean;
  notHideOnSubmit?: boolean;
  buttonsWrapperClassName?: ClassName;
}

function useModal({
  afterSubmit,
  onClose,
  onOpen,
  onSubmit,
  onToggle,
  preventModalClose,
  isModalOpen = false,
  notHideOnSubmit
}: ModalOptions = {}) {
  const [isOpen, setIsOpen] = useState<boolean>(isModalOpen);
  const isClosing = useRef(false);

  const handleSetIsOpen = useCallback((value: boolean) => {
    setIsOpen(value);

    if (!value && !isClosing.current) {
      isClosing.current = true;
    }

    if (!!value && !!isClosing.current) {
      isClosing.current = false;
    }
  }, []);

  const showModal = useCallback(() => {
    handleSetIsOpen(true);
    onOpen && onOpen();
    onToggle && onToggle(true);
  }, [handleSetIsOpen, onOpen, onToggle]);

  const hideModal = useCallback(() => {
    if (!preventModalClose) {
      isClosing.current = true;
      handleSetIsOpen(false);
    }
    onClose && onClose();
    onToggle && onToggle(false);
  }, [handleSetIsOpen, onClose, onToggle, preventModalClose]);

  const handleSubmit = useCallback(() => {
    if (onSubmit) {
      onSubmit()
        .then(() => {
          if (!notHideOnSubmit) {
            hideModal();
          }
          afterSubmit && afterSubmit();
        })
        .catch((e) =>
          console.log('useModal handleSubmit', { e }, get(e, 'message'))
        );
    } else {
      if (!notHideOnSubmit) {
        hideModal();
      }
      afterSubmit && afterSubmit();
    }
  }, [onSubmit, notHideOnSubmit, afterSubmit, hideModal]);

  return {
    isOpen,
    showModal,
    hideModal,
    handleSubmit,
    isClosing: isClosing.current
  };
}

export default useModal;
