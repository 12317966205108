import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface WhiteboardsKeyType {
  canNotDeterminatePage: LocalesKey;
  connectionLostTryingToReconnect: LocalesKey;
  connectionSuccessfullyRestored: LocalesKey;
  couldNotRestoreTheConnection: LocalesKey;
  createMissingShapes: LocalesKey;
  draftCommentIsNotVisibleToOtherProjectMembersUntilItIsPublished: LocalesKey;
  goTo: LocalesKey;
  imageAlreadyAttachedToThisTask: LocalesKey;
  markAsResolved: LocalesKey;
  markAsUnresolved: LocalesKey;
  open: LocalesKey;
  page: LocalesKey;
  pageName: LocalesKey;
  pleaseSelectAnImage: LocalesKey;
  preview: LocalesKey;
  project: LocalesKey;
  publishedCommentIsVisibleToAllProjectMembers: LocalesKey;
  records: LocalesKey;
  select: LocalesKey;
  selectPage: LocalesKey;
  sendAndStay: LocalesKey;
  sendToTask: LocalesKey;
  sentToWhiteboard: LocalesKey;
  shapes: LocalesKey;
  singularLowerCase: LocalesKey;
  task: LocalesKey;
  thereIsNoMissingShapes: LocalesKey;
  updateEventDate: LocalesKey;
  whiteboardBackups: LocalesKey;
  whiteboardDeleteEvents: LocalesKey;
  whiteboardIsExportingToPdf: LocalesKey;
  wouldYouLikeToBeRedirectedToTheWhiteboard: LocalesKey;
  zoomIn: LocalesKey;
  zoomOut: LocalesKey;
  zoomTo100: LocalesKey;
  zoomToFit: LocalesKey;
  zoomToSelection: LocalesKey;
}

export const whiteboardsKeys = keyPathMirror<WhiteboardsKeyType, string>(
  {
    canNotDeterminatePage: null,
    connectionLostTryingToReconnect: null,
    connectionSuccessfullyRestored: null,
    couldNotRestoreTheConnection: null,
    createMissingShapes: null,
    draftCommentIsNotVisibleToOtherProjectMembersUntilItIsPublished: null,
    goTo: null,
    imageAlreadyAttachedToThisTask: null,
    markAsResolved: null,
    markAsUnresolved: null,
    open: null,
    page: null,
    pageName: null,
    pleaseSelectAnImage: null,
    preview: null,
    project: null,
    publishedCommentIsVisibleToAllProjectMembers: null,
    records: null,
    select: null,
    selectPage: null,
    sendAndStay: null,
    sendToTask: null,
    sentToWhiteboard: null,
    shapes: null,
    singularLowerCase: null,
    task: null,
    thereIsNoMissingShapes: null,
    updateEventDate: null,
    whiteboardBackups: null,
    whiteboardDeleteEvents: null,
    whiteboardIsExportingToPdf: null,
    wouldYouLikeToBeRedirectedToTheWhiteboard: null,
    zoomIn: null,
    zoomOut: null,
    zoomTo100: null,
    zoomToFit: null,
    zoomToSelection: null
  },
  'models.whiteboards.'
);
