import React, { useState, useCallback } from 'react';

import { ClassName } from '../../../../types';
import { AvBillingInfoNanoID } from '../../avBillingInfosTypes';

import { AvBillingInfoPublicUrl } from '../../AvBillingInfoPublicUrl';
import { StaticImageHelper } from '../../../../helpers/StaticImageHelper';

type AvBillingInfosUsersIndexTableProps = {
  avBillingInfoNanoId: AvBillingInfoNanoID;
  className: ClassName;
};

const avLogoSrcVersions = {
  '3x': '/images/logo@3x.png',
  '2x': '/images/logo@2x.png',
  '1x': '/images/logo.png'
};

function AvBillingInfoLogo({
  avBillingInfoNanoId,
  className
}: AvBillingInfosUsersIndexTableProps) {
  const [hidden, setHidden] = useState<boolean>(false);

  const handleError = useCallback<() => void>(() => {
    setHidden(true);
  }, []);

  const handleLoad = useCallback<() => void>(() => {
    setHidden(false);
  }, []);

  return hidden ? (
    <StaticImageHelper
      className={className}
      src={avLogoSrcVersions['1x']}
      srcSetData={avLogoSrcVersions}
      alt="logo"
    />
  ) : (
    <object
      className={className}
      data={avLogoSrcVersions['1x']}
      type="image/png"
    >
      <img
        className={className}
        src={AvBillingInfoPublicUrl.logo(avBillingInfoNanoId)}
        alt="logo"
        onError={handleError}
        onLoad={handleLoad}
        hidden={hidden}
      />
    </object>
  );
}

export default AvBillingInfoLogo;
