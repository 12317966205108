import { TeamNanoID, TeamTeamTypeEnum } from '../../teamsTypes';

import {
  FetchShowTeamQueryResponse,
  FETCH_SHOW_TEAM_QUERY
} from '../../queries/fetchShowTeam.query';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useFinTeam } from '../useFinTeam';

import { isSelfCompany } from '../../utils/isSelfCompany';

import { TeamCache } from '../../TeamCache';

interface TeamShowPageOptions {
  teamNanoId: TeamNanoID;
  staleTime?: number;
}

function useTeamShowPage({ teamNanoId, staleTime }: TeamShowPageOptions) {
  const currentUser = useCurrentUser();

  const {
    team,
    teamFetched,
    teamErrorData,
    teamError,
    teamIsPlaceholderData,
    teamLoading
  } = useFinTeam<FetchShowTeamQueryResponse>({
    cacheKey: TeamCache.showCacheKey(),
    query: FETCH_SHOW_TEAM_QUERY,
    uuid: teamNanoId,
    options: {
      enabled: !!teamNanoId,
      enabledPlaceholder: !!teamNanoId,
      staleTime
    }
  });

  const isSelf = isSelfCompany({
    company: team,
    userNanoId: currentUser.nanoId
  });

  const isSelfLoading = !team && !teamFetched;

  const isWorker = team?.teamType === TeamTeamTypeEnum.WORKERS;

  return {
    team,
    teamFetched,
    teamErrorData,
    teamError,
    teamIsPlaceholderData,
    teamLoading,
    isSelf,
    isSelfLoading,
    isWorker
  };
}

export default useTeamShowPage;
