import { ClassName } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { useCompanyBalanceAccounts } from '../../../../../../accounts/hooks/useCompanyBalanceAccounts';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { NextPureIconBadgeLinkHelper } from '../../../../../../../helpers/links/NextPureIconBadgeLinkHelper';

import { AccountPath } from '../../../../../../accounts/AccountPath';
import { profilesKeys } from '../../../../../../../locales/keys';

interface UserFinanceDebitFinBadgeLinkProps {
  className?: ClassName;
  badgeClassName?: ClassName;
}

function UserFinanceDebitFinBadgeLink({
  className,
  badgeClassName
}: UserFinanceDebitFinBadgeLinkProps) {
  const currentUser = useCurrentUser();

  const { companyBalance, companyBalanceAccountsFetched } =
    useCompanyBalanceAccounts({
      companyNanoId: currentUser?.currentTeam?.nanoId
    });

  if (!currentUser && !companyBalanceAccountsFetched) {
    return null;
  }

  return (
    <NextPureIconBadgeLinkHelper
      className={
        className ||
        'focus:outline-none hover:bg-opacity-15 hover:bg-gray-500 px-2.5 align-middle hover:text-black dark:hover:text-white hidden sm:flex items-center gap-1.5 mb-px'
      }
      badgeClassName={badgeClassName}
      i18nText={profilesKeys.balance}
      icon={IconsEnum.CASH_OUTLINE}
      iconClassName="w-3.5 h-3.5"
      badgeValue={companyBalance || 0}
      badgePrefix="$"
      href={AccountPath.paymentsBalanceSheet()}
    />
  );
}

export default UserFinanceDebitFinBadgeLink;
