import React, { useCallback } from 'react';

import { ClassName, I18nText, IsDisabled } from '../../types';

import { Translate } from '../Translate';

export interface RadioButtonInputProps<ValueType> {
  disabled?: IsDisabled;
  text?: string;
  i18nText?: I18nText;
  i18nDescription?: I18nText;
  descriptionClassName?: ClassName;
  labelClassName?: ClassName;
  name: string;
  onChange: (buttonValue: ValueType) => void;
  option: ValueType;
  optionClassName?: ClassName;
  value: ValueType;
  wrapperClassName?: ClassName;
}

function RadioButtonInput<ValueType extends string | number>({
  disabled,
  text,
  i18nText,
  i18nDescription,
  descriptionClassName,
  labelClassName,
  name,
  onChange,
  option,
  optionClassName,
  value,
  wrapperClassName
}: RadioButtonInputProps<ValueType>) {
  const handleChange = useCallback(() => {
    onChange(option);
  }, [option, onChange]);

  return (
    <div className={wrapperClassName || 'mr-4'}>
      <label className={labelClassName || 'inline-flex items-center'}>
        <input
          type="radio"
          className="basic-radio"
          name={name}
          value={option}
          checked={value === option}
          onChange={handleChange}
          disabled={disabled}
        />

        <div>
          {i18nText || text ? (
            <span className={optionClassName || 'ml-2'}>
              {i18nText ? <Translate id={i18nText} /> : text}
            </span>
          ) : (
            <span className={optionClassName}>{option}</span>
          )}

          {i18nDescription && (
            <div className={descriptionClassName}>
              <Translate id={i18nDescription} />
            </div>
          )}
        </div>
      </label>
    </div>
  );
}

export default RadioButtonInput;
