import { GraphQLClient } from 'graphql-request';

// must be the same as in next.config.js
const graphQLPath =
  process.env.DEVELOP_API_URI ||
  process.env.STAGING_API_URI ||
  process.env.MASTER_API_URI;

export const graphQLReadFinPath = process.env.GRAPHQL_READ_FIN_API_URI;

export const graphQLHostname = graphQLPath
  ? new URL(graphQLPath)?.hostname
  : undefined;

export const graphQLReadFinHostname = graphQLReadFinPath
  ? new URL(graphQLReadFinPath)?.hostname
  : undefined;

export const graphQLClient = new GraphQLClient(graphQLPath, {
  credentials: 'include',
  mode: 'cors'
});

export const graphQLReadFinClient = new GraphQLClient(graphQLReadFinPath, {
  credentials: 'include',
  mode: 'cors'
});
