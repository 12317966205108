import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';
import { UserGroupID, UserID, UserUUID } from '../users/usersTypes';
import { TeamClientStatusesEnum, TeamID } from './teamsTypes';

export class TeamCache {
  static showCacheKey() {
    return 'team';
  }

  static showFinTeamStatusCacheKey() {
    return 'fin-team-status';
  }

  static showBillingInfoCacheKey() {
    return 'team-billing-info';
  }

  static showCompanyCacheKey() {
    return 'company';
  }

  static showFinCompanyCacheKey() {
    return 'fin-company';
  }

  static showCompanyBasicInfoCacheKey() {
    return 'company-basic-info';
  }

  static showCompanyIdsCacheKey() {
    return 'company-ids';
  }

  static showCompanyRevenueCacheKey() {
    return 'company-revenue';
  }

  static showCompanyAccountReceivablesCacheKey() {
    return 'company-account-receivables';
  }

  static showCompanyItemTypesCacheKey() {
    return 'company-item-types';
  }

  static showCompanyAddFundsCacheKey() {
    return 'company-add-funds';
  }

  static showCompanyTimeZoneCacheKey() {
    return 'company-time-zone';
  }

  static showTeamItemSidebarCacheKey() {
    return 'team-item-sidebar';
  }

  static showRedirectCacheKey(teamId: TeamID) {
    return `team-${teamId}-redirect`;
  }

  static indexSelectCacheKey(fieldName: string) {
    return `teams-select-${fieldName}`;
  }

  static defaultSelectCacheKey(fieldName: string) {
    return `default-teams-select-${fieldName}`;
  }

  static selectFieldCacheKey() {
    return 'select-field-teams';
  }

  static selectFieldSelectedTeamsCacheKey() {
    return 'select-field-teams-selected-teams';
  }

  static companiesCacheKey() {
    return 'companies';
  }

  static companiesMyCompaniesCacheKey() {
    return 'companies-my-companies';
  }

  static companiesMyActiveCompaniesCacheKey() {
    return 'companies-my-active-companies';
  }

  static companiesUnassignedCacheKey() {
    return 'companies-unassigned';
  }

  static companiesFavoriteCacheKey() {
    return 'companies-favorite';
  }

  static companiesStatusCacheKey(teamClientStatus: TeamClientStatusesEnum) {
    return `companies-status-${teamClientStatus}`;
  }

  static companiesTotalCountStatusCacheKey(
    teamClientStatus: TeamClientStatusesEnum
  ) {
    return `companies-total-count-status-${teamClientStatus}`;
  }

  static companiesTotalCountFinStatusCacheKey(
    teamClientStatus: TeamClientStatusesEnum
  ) {
    return `companies-total-count-fin-status-${teamClientStatus}`;
  }

  static filteredCompaniesCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `companies-filtered-${searchFilterNanoId}`;
  }

  static performersCacheKey() {
    return 'performer-companies';
  }

  static performersFavoriteCacheKey() {
    return 'performer-companies-favorite';
  }

  static performersBlockedCacheKey() {
    return 'performer-companies-blocked';
  }

  static performersUnassignedCacheKey() {
    return 'performer-companies-unassigned';
  }

  static performersMyPerformersCacheKey() {
    return 'performer-companies-my-performers';
  }

  static filteredPerformersCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `performer-companies-filtered-${searchFilterNanoId}`;
  }

  static userCompany(id: UserUUID | UserID) {
    return `user-${id}-company`;
  }

  static userGroupCompanies(id: UserGroupID) {
    return `user-group-${id}-companies`;
  }

  static indexUserGroupCompanies() {
    return 'user-group-companies';
  }

  static selectedTeamSelectFieldCacheKey(fieldName: string) {
    return `team-select-field-${fieldName}`;
  }

  static smartContractCompaniesCacheKey() {
    return 'smart-contract-companies';
  }

  static filesCacheKey(teamId: TeamID) {
    return `team-${teamId}-files`;
  }

  static imagesCacheKey(teamId: TeamID) {
    return `team-${teamId}-images`;
  }
}
