import React, {
  ForwardedRef,
  FocusEvent,
  ChangeEvent,
  ClipboardEvent,
  memo,
  MouseEvent,
  KeyboardEvent,
  WheelEvent,
  useRef,
  useState,
  useMemo,
  useEffect
} from 'react';
import cl from 'classnames';

import { ClassName, FieldIcons, I18nText } from '../../../types';
import { IconsEnum } from '../../../assets/icons/types';

import { useTranslate } from '../../../common/hooks/useTranslate';

import { PureIconButtonHelper } from '../../buttons/PureIconButtonHelper';

import { Translate } from '../../Translate';
import { Icon } from '../../Icon';

interface InputFieldProps {
  addInputClassName?: ClassName;
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  error?: string | null;
  errorClassName?: ClassName;
  id?: string;
  inputClassName?: ClassName;
  inputWrapperClassName?: ClassName;
  inputFieldWrapperClassName?: ClassName;
  label?: string;
  i18nLabel?: I18nText;
  i18nPlaceholder?: I18nText;
  labelClassName?: ClassName;
  leftIcon?: FieldIcons | null;
  max?: number | string;
  min?: number | string;
  name: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: MouseEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  placeholder?: string;
  required?: boolean;
  showError?: boolean;
  showErrorIcon?: boolean;
  step?: number | 'any';
  type?: string;
  value?: string;
  prefix?: string;
  suffix?: string;
  readOnly?: boolean;
  autoComplete?: string;
  rightButtonIcon?: IconsEnum;
  onRightButtonClick?: () => void;
  withoutScroll?: boolean;
  prefixClassName?: ClassName;
  prefixWrapperClassName?: ClassName;
  lang?: string;
}
const enum InputFieldMaxValueTypes {
  DATE = 'date',
  DATETIME_LOCAL = 'datetime-local',
  NUMBER = 'number'
}

const InputFieldMaxDefaultValues = {
  [InputFieldMaxValueTypes.DATE]: '9999-12-31',
  [InputFieldMaxValueTypes.DATETIME_LOCAL]: '9999-12-31T23:59:59',
  [InputFieldMaxValueTypes.NUMBER]: undefined
};

const paddingSpace = 20;

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      addInputClassName,
      autoFocus = false,
      className,
      disabled,
      error,
      errorClassName,
      id,
      inputClassName,
      inputWrapperClassName,
      inputFieldWrapperClassName,
      label,
      i18nLabel,
      labelClassName,
      leftIcon = null,
      max,
      min,
      name,
      placeholder,
      i18nPlaceholder,
      onBlur,
      onFocus,
      onChange,
      onClick,
      onKeyDown,
      required,
      showError = true,
      showErrorIcon = true,
      step,
      type,
      value,
      prefix,
      suffix,
      readOnly = false,
      autoComplete,
      rightButtonIcon,
      onRightButtonClick,
      withoutScroll = false,
      prefixClassName,
      prefixWrapperClassName,
      lang
    }: InputFieldProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const { t } = useTranslate();

    const prefixEl = useRef(null);
    const suffixEl = useRef(null);

    const [inputPaddingLeft, setInputPaddingLeft] = useState();
    const [inputPaddingRight, setInputPaddingRight] = useState();

    useEffect(() => {
      setInputPaddingLeft(
        prefix
          ? (prefixEl?.current?.offsetWidth || 0) + paddingSpace
          : undefined
      );
    }, [prefix]);

    useEffect(() => {
      setInputPaddingRight(
        suffix
          ? (suffixEl?.current?.offsetWidth || 0) + paddingSpace
          : undefined
      );
    }, [suffix]);

    const handleWheel = (e: WheelEvent<HTMLInputElement>) => {
      if (withoutScroll) {
        e?.currentTarget?.blur();
      }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (type === 'number' && (min as number) >= 0 && e?.code === 'Minus') {
        e?.preventDefault();
      }
      onKeyDown?.(e);
    };

    const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
      const clipboardData = e?.clipboardData;

      const pastedData = parseFloat(clipboardData?.getData('text'));

      if (
        type === 'number' &&
        min !== undefined &&
        pastedData < (min as number)
      ) {
        e?.preventDefault();
      }
    };

    return (
      <div className={className}>
        {i18nLabel || label ? (
          <label
            htmlFor={id || name}
            className={cl(
              labelClassName ||
                'block text-sm font-medium text-gray-700 dark:text-gray-300'
            )}
          >
            {i18nLabel ? <Translate id={i18nLabel} /> : label}
          </label>
        ) : null}
        <div className={inputWrapperClassName}>
          <div className={inputFieldWrapperClassName}>
            {leftIcon && (
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">{leftIcon}</span>
              </div>
            )}
            <input
              autoFocus={autoFocus}
              className={cl(
                inputClassName || 'basic-input',
                addInputClassName,
                {
                  'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 dark:focus:border-red-500 dark:focus:ring-red-500 dark:border-red-700 dark:placeholder-red-600 dark:bg-gray-800 dark:text-red-500':
                    error
                }
              )}
              style={{
                paddingLeft: inputPaddingLeft,
                paddingRight: inputPaddingRight
              }}
              disabled={disabled}
              id={id || name}
              max={max || InputFieldMaxDefaultValues[type]}
              min={min}
              name={name}
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={onChange}
              onClick={onClick}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              onWheel={handleWheel}
              placeholder={i18nPlaceholder ? t(i18nPlaceholder) : placeholder}
              ref={ref}
              required={required}
              step={step}
              type={type}
              value={value}
              readOnly={readOnly}
              autoComplete={autoComplete}
              lang={lang}
            />
            <div
              className={
                prefixWrapperClassName ||
                'absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'
              }
            >
              {prefix && (
                <span
                  ref={prefixEl}
                  className={prefixClassName || 'text-gray-500 sm:text-sm'}
                >
                  {prefix}
                </span>
              )}
            </div>

            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              {suffix && (
                <span ref={suffixEl} className="text-gray-500 sm:text-sm">
                  {suffix}
                </span>
              )}

              {error && showError && showErrorIcon && (
                <Icon
                  className="h-5 w-5 text-red-500"
                  icon={IconsEnum.EXCLAMATION_CIRCLE}
                />
              )}
            </div>
          </div>
          {onRightButtonClick && (
            <PureIconButtonHelper
              className="py-1.5 pl-1.5 pr-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              icon={rightButtonIcon}
              onClick={onRightButtonClick}
              iconClassName="h-5 w-5"
            />
          )}
        </div>

        {error && showError && (
          <p className={cl(errorClassName || 'mt-2 text-sm text-red-600')}>
            {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
          </p>
        )}
      </div>
    );
  }
);

InputField.displayName = 'InputField';

export default memo(InputField);
