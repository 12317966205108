import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

interface InvoicesKeyType {
  accountTransactionCount: LocalesKey;
  addFunds: LocalesKey;
  addANote: LocalesKey;
  addToDraft: LocalesKey;
  allInvoices: LocalesKey;
  allItemsBelowAreExpectedToBePaidHoweverYouMayChooseSpecificItemsToBeIncludedInTheCreatedOpenInvoice: LocalesKey;
  allSmartContractsContainingItemsFromThisInvoiceWillBeCanceledAreYouSureYouWantToCancelSmartContracts: LocalesKey;
  amount: LocalesKey;
  anotherAddress: LocalesKey;
  archivizerAccount: LocalesKey;
  areYouSureYouWantToCreateInvoice: LocalesKey;
  areYouSureYouWantToCancelThisInvoice: LocalesKey;
  areYouSureYouWantToCancelSmartContractsThisInvoice: LocalesKey;
  areYouSureYouWantToUncollectibleThisInvoice: LocalesKey;
  areYouSureYouWantToSendThisInvoice: LocalesKey;
  areYouSureYouWantToCheckAndPayThisInvoice: LocalesKey;
  areYouSureYouWantToPayFromDeposit: LocalesKey;
  avInvoiceBillingInfoPaymentMethod: LocalesKey;
  billingAddress: LocalesKey;
  billingInfo: LocalesKey;
  cancel: LocalesKey;
  cancelSmartContracts: LocalesKey;
  cardInfo: LocalesKey;
  chargeback: LocalesKey;
  clientId: LocalesKey;
  companyName: LocalesKey;
  confirmITC: LocalesKey;
  contactEmailAddress: LocalesKey;
  contactName: LocalesKey;
  contractor: LocalesKey;
  contactPhoneNumber: LocalesKey;
  confirmPayment: LocalesKey;
  confirmInvoicePayment: LocalesKey;
  create: LocalesKey;
  createNewDraft: LocalesKey;
  created: LocalesKey;
  createYourInvoices: LocalesKey;
  creditCard: LocalesKey;
  checkAndPay: LocalesKey;
  checkAndPayV4: LocalesKey;
  date: LocalesKey;
  details: LocalesKey;
  deposit: LocalesKey;
  depositInvoices: LocalesKey;
  draftName: LocalesKey;
  dueDate: LocalesKey;
  dueDateColon: LocalesKey;
  generalLedger: LocalesKey;
  generalLedgerId: LocalesKey;
  filter: LocalesKey;
  id: LocalesKey;
  ids: LocalesKey;
  invoiceCreated: LocalesKey;
  invoiceBillingEmail: LocalesKey;
  invoiceHasNoItems: LocalesKey;
  issueDate: LocalesKey;
  issueDateColon: LocalesKey;
  list: LocalesKey;
  notes: LocalesKey;
  notesColon: LocalesKey;
  notOverdue: LocalesKey;
  otherInvoices: LocalesKey;
  openProforma: LocalesKey;
  plural: LocalesKey;
  parentInvoiceId: LocalesKey;
  pluralLowerCase: LocalesKey;
  primaryInvoiceId: LocalesKey;
  performerInvoices: LocalesKey;
  openPerformerInvoices: LocalesKey;
  sentPerformerInvoices: LocalesKey;
  currentPerformerInvoices: LocalesKey;
  overduePerformerInvoices: LocalesKey;
  refreshStatus: LocalesKey;
  refund: LocalesKey;
  refunded: LocalesKey;
  regular: LocalesKey;
  removeFromDraft: LocalesKey;
  secondaryInvoiceId: LocalesKey;
  send: LocalesKey;
  sendToClient: LocalesKey;
  singular: LocalesKey;
  terms: LocalesKey;
  termsColon: LocalesKey;
  type: LocalesKey;
  messages: {
    areYouSureYouWantToCancelInvoice: LocalesKey;
    creditCardMethods: LocalesKey;
    onceWeReceiveYourPayment: LocalesKey;
    payoneerWarning: LocalesKey;
    stripeWarning: LocalesKey;
    weHaveSentTheInvoiceToYourEmail: LocalesKey;
    weHaveSentReceiptToYourEmail: LocalesKey;
    wireMethods: LocalesKey;
    invoiceHasBeen: {
      paid: LocalesKey;
      refund: LocalesKey;
      chargedback: LocalesKey;
    };
    finalPaymentIsExpected: LocalesKey;
  };
  myItemsOnly: LocalesKey;
  noPlural: LocalesKey;
  account: LocalesKey;
  overdue: LocalesKey;
  payer: LocalesKey;
  payBill: LocalesKey;
  payFromDeposit: LocalesKey;
  payFromAv: LocalesKey;
  paymentDate: LocalesKey;
  paymentForIsProcessing: LocalesKey;
  paymentMethod: LocalesKey;
  paymentMethodWithColon: LocalesKey;
  paymentProcessing: LocalesKey;
  payments: LocalesKey;
  prepayment: LocalesKey;
  prepaymentAmount: LocalesKey;
  proformaInvoice: LocalesKey;
  projectName: LocalesKey;
  receivedAmount: LocalesKey;
  returnReason: LocalesKey;
  sender: LocalesKey;
  selectDraft: LocalesKey;
  status: LocalesKey;
  updateDueDate: LocalesKey;
  updateIssueDate: LocalesKey;
  search: LocalesKey;
  searchByGeneralLedgerId: LocalesKey;
  searchById: LocalesKey;
  searchByPayer: LocalesKey;
  total: LocalesKey;
  userId: LocalesKey;
  view: LocalesKey;
  viewOpenInvoices: LocalesKey;
  void: LocalesKey;
  wire: LocalesKey;
  selectPayerSubbook: LocalesKey;
  statuses: {
    draft: LocalesKey;
    open: LocalesKey;
    paid: LocalesKey;
    prepaid: LocalesKey;
    sent: LocalesKey;
    canceled: LocalesKey;
    uncollectible: LocalesKey;
    void: LocalesKey;
    none: LocalesKey;
    upcoming: LocalesKey;
  };
  grouping: {
    projectTask: LocalesKey;
    batchTask: LocalesKey;
    monthInvoiceCreated: LocalesKey;
    monthTaskCreated: LocalesKey;
    monthTaskDueDate: LocalesKey;
    monthProjectCreated: LocalesKey;
    monthTaskCreatedTask: LocalesKey;
    monthTaskDueDateTask: LocalesKey;
    monthProjectCreatedProject: LocalesKey;
    invoiceTag: LocalesKey;
    split: LocalesKey;
    batch: LocalesKey;
    monthTaskDone: LocalesKey;
    monthProjectClosed: LocalesKey;
  };
}

export const invoicesKeys = keyPathMirror<InvoicesKeyType, string>(
  {
    accountTransactionCount: null,
    addFunds: null,
    addANote: null,
    addToDraft: null,
    allInvoices: null,
    allItemsBelowAreExpectedToBePaidHoweverYouMayChooseSpecificItemsToBeIncludedInTheCreatedOpenInvoice:
      null,
    allSmartContractsContainingItemsFromThisInvoiceWillBeCanceledAreYouSureYouWantToCancelSmartContracts:
      null,
    amount: null,
    anotherAddress: null,
    archivizerAccount: null,
    areYouSureYouWantToCreateInvoice: null,
    areYouSureYouWantToCancelThisInvoice: null,
    areYouSureYouWantToCancelSmartContractsThisInvoice: null,
    areYouSureYouWantToUncollectibleThisInvoice: null,
    areYouSureYouWantToSendThisInvoice: null,
    areYouSureYouWantToPayFromDeposit: null,
    areYouSureYouWantToCheckAndPayThisInvoice: null,
    avInvoiceBillingInfoPaymentMethod: null,
    billingAddress: null,
    billingInfo: null,
    cancel: null,
    cancelSmartContracts: null,
    cardInfo: null,
    chargeback: null,
    checkAndPay: null,
    checkAndPayV4: null,
    clientId: null,
    companyName: null,
    confirmITC: null,
    contactEmailAddress: null,
    contactName: null,
    contractor: null,
    contactPhoneNumber: null,
    confirmPayment: null,
    confirmInvoicePayment: null,
    create: null,
    createNewDraft: null,
    createYourInvoices: null,
    created: null,
    creditCard: null,
    date: null,
    details: null,
    deposit: null,
    depositInvoices: null,
    draftName: null,
    dueDate: null,
    dueDateColon: null,
    generalLedger: null,
    generalLedgerId: null,
    filter: null,
    id: null,
    ids: null,
    invoiceCreated: null,
    invoiceBillingEmail: null,
    invoiceHasNoItems: null,
    issueDate: null,
    issueDateColon: null,
    list: null,
    notes: null,
    notesColon: null,
    notOverdue: null,
    otherInvoices: null,
    openProforma: null,
    plural: null,
    parentInvoiceId: null,
    pluralLowerCase: null,
    primaryInvoiceId: null,
    refreshStatus: null,
    refund: null,
    refunded: null,
    regular: null,
    removeFromDraft: null,
    secondaryInvoiceId: null,
    send: null,
    sendToClient: null,
    singular: null,
    terms: null,
    termsColon: null,
    total: null,
    type: null,
    userId: null,
    messages: {
      areYouSureYouWantToCancelInvoice: null,
      creditCardMethods: null,
      onceWeReceiveYourPayment: null,
      payoneerWarning: null,
      stripeWarning: null,
      weHaveSentTheInvoiceToYourEmail: null,
      weHaveSentReceiptToYourEmail: null,
      wireMethods: null,
      invoiceHasBeen: {
        paid: null,
        refund: null,
        chargedback: null
      },
      finalPaymentIsExpected: null
    },
    myItemsOnly: null,
    noPlural: null,
    account: null,
    overdue: null,
    payer: null,
    payBill: null,
    payFromDeposit: null,
    payFromAv: null,
    paymentDate: null,
    paymentForIsProcessing: null,
    paymentMethod: null,
    paymentMethodWithColon: null,
    paymentProcessing: null,
    payments: null,
    performerInvoices: null,
    openPerformerInvoices: null,
    sentPerformerInvoices: null,
    currentPerformerInvoices: null,
    overduePerformerInvoices: null,
    prepayment: null,
    prepaymentAmount: null,
    proformaInvoice: null,
    projectName: null,
    receivedAmount: null,
    returnReason: null,
    sender: null,
    search: null,
    searchByGeneralLedgerId: null,
    searchById: null,
    searchByPayer: null,
    selectDraft: null,
    status: null,
    updateDueDate: null,
    updateIssueDate: null,
    view: null,
    viewOpenInvoices: null,
    void: null,
    wire: null,
    selectPayerSubbook: null,
    statuses: {
      draft: null,
      open: null,
      paid: null,
      prepaid: null,
      sent: null,
      canceled: null,
      uncollectible: null,
      void: null,
      none: null,
      upcoming: null
    },
    grouping: {
      projectTask: null,
      batchTask: null,
      monthInvoiceCreated: null,
      monthTaskCreated: null,
      monthTaskDueDate: null,
      monthProjectCreated: null,
      monthTaskCreatedTask: null,
      monthTaskDueDateTask: null,
      monthProjectCreatedProject: null,
      invoiceTag: null,
      split: null,
      batch: null,
      monthTaskDone: null,
      monthProjectClosed: null
    }
  },
  'models.invoices.'
);
