import { IconProps } from '../types';

function ChatGptSolidIcon({ className, id }: IconProps) {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className || 'h-4 w-4'}
    >
      <path
        clipRule="evenodd"
        d="M17.7 8.4c.4-1.2.3-2.6-.4-3.7-1-1.7-3-2.6-4.9-2.2-.8-1-2.1-1.5-3.3-1.5-2 0-3.7 1.3-4.3 3.1-1.3.3-2.4 1.1-3 2.2-1.1 1.7-.8 3.9.5 5.3-.4 1.2-.3 2.6.4 3.7 1 1.7 3 2.6 4.9 2.2.9 1 2.1 1.5 3.4 1.5 2 0 3.7-1.3 4.3-3.1 1.3-.3 2.4-1.1 3-2.2 1-1.7.7-3.9-.6-5.3zm-6.8 9.4c-.8 0-1.6-.3-2.2-.8 0 0 .1 0 .1-.1l3.6-2.1c.2-.1.3-.3.3-.5v-5l1.5.9v4.2c.1 1.9-1.4 3.4-3.3 3.4zm-7.2-3.1c-.4-.7-.5-1.5-.4-2.3 0 0 .1 0 .1.1L7 14.6c.2.1.4.1.6 0l4.4-2.5v1.7L8.3 16c-1.6.9-3.7.3-4.6-1.3zm-.9-7.8c.4-.7 1-1.2 1.8-1.5V9.6c0 .2.1.4.3.5l4.4 2.5-1.5.9h-.1l-3.7-2c-1.6-.9-2.2-3-1.2-4.6zm12.4 2.9l-4.4-2.5 1.5-.9h.1L16 8.5c1.6.9 2.2 3 1.2 4.6-.4.7-1 1.2-1.8 1.5v-4.3c.1-.2 0-.4-.2-.5zm1.5-2.3s-.1 0-.1-.1l-3.6-2c-.2-.1-.4-.1-.6 0L8.1 7.9V6.2L11.7 4c1.6-.9 3.7-.4 4.6 1.2.4.8.5 1.6.4 2.3zm-9.5 3.1l-1.5-.8V5.5c0-1.9 1.5-3.4 3.4-3.4.8 0 1.6.3 2.2.8 0 0-.1 0-.1.1L7.5 5.1c-.2.1-.3.3-.3.5v5zm.9-1.7L10 7.7l1.9 1.1v2.3L10 12.3l-1.9-1.1V8.9z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default ChatGptSolidIcon;
