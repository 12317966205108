import { keyPathMirror } from 'key-path-mirror';

import { LocalesKey } from '../localesKeysTypes';

export interface CustomFieldsKeyType {
  addField: LocalesKey;
  editField: LocalesKey;
  deleteField: LocalesKey;
  customProformaFields: LocalesKey;
  locations: {
    invoiceTitle: LocalesKey;
    invoiceHeader: LocalesKey;
    billFrom: LocalesKey;
    billTo: LocalesKey;
    beforeItems: LocalesKey;
    amountDue: LocalesKey;
    notesAndTerms: LocalesKey;
  };
  selectLocation: LocalesKey;
}

export const customFieldsKeys = keyPathMirror<CustomFieldsKeyType, string>(
  {
    addField: null,
    editField: null,
    deleteField: null,
    customProformaFields: null,
    locations: {
      invoiceTitle: null,
      invoiceHeader: null,
      billFrom: null,
      billTo: null,
      beforeItems: null,
      amountDue: null,
      notesAndTerms: null
    },
    selectLocation: null
  },
  'models.customFields.'
);
