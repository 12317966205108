import { useEffect } from 'react';
import { NextRouter } from 'next/router';
import includes from 'lodash/includes';

import AuthenticatedUser from '../useAuth/AuthenticatedUser';

import { AccountPath } from '../../../main/accounts/AccountPath';
import { AuthPath } from '../../AuthPath';

interface AuthRedirectsOptions {
  currentUser: AuthenticatedUser | null;
  router: NextRouter;
}

const allowedBlockedUrls = [AccountPath.paymentsBalanceSheet()];

export function useAuthRedirects({
  currentUser,
  router
}: AuthRedirectsOptions) {
  useEffect(() => {
    // Blocked users redirect
    if (
      currentUser?.blocked &&
      !includes(router.pathname, AuthPath.base()) &&
      !includes(router.pathname, AuthPath.registerShort()) &&
      !includes(allowedBlockedUrls, router.pathname)
    ) {
      void router.push(AccountPath.paymentsBalanceSheet());
    }
  }, [currentUser, router]);
}
