import { CustomFieldsKeyType } from '../../keys';

const itemCategoriesKeys: CustomFieldsKeyType = {
  addField: 'Add field',
  editField: 'Edit field',
  deleteField: 'Delete field',
  customProformaFields: 'Custom proforma fields',
  locations: {
    invoiceTitle: 'Invoice title',
    invoiceHeader: 'Invoice header',
    billFrom: 'Bill from',
    billTo: 'Bill to',
    beforeItems: 'Before items',
    amountDue: 'Amount due',
    notesAndTerms: 'Notes & terms'
  },
  selectLocation: 'Select location'
};

export default itemCategoriesKeys;
