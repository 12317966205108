export enum ImageItemImageVersions {
  MiniThumb30x30 = 'mini_thumb_30x30',
  MiniThumb45x30 = 'mini_thumb_45x30',
  MiniThumb45x45 = 'mini_thumb_45x45',
  MiniThumb70x70 = 'mini_thumb_70x70',
  MiniThumb48x48 = 'mini_thumb_48x48',
  MiniThumb96x96 = 'mini_thumb_96x96',
  MiniThumb144x144 = 'mini_thumb_144x144',
  MiniThumb320x320 = 'mini_thumb_320x320',
  MiniThumb640x640 = 'mini_thumb_640x640',
  MiniThumb960x960 = 'mini_thumb_960x960',
  Thumb = 'thumb', // 75x75
  Thumb170x110 = 'thumb_170x110',
  Thumb150x150 = 'thumb_150x150',
  Thumb160x160 = 'thumb_160x160',
  PdfThumb = 'pdf_thumb', // 120x120
  PdfFulThumb = 'pdf_full_thumb', // 550x550
  MiddleThumb = 'middle_thumb', // 140x115
  MiddleThumb142x163 = 'middle_thumb_142x163',
  MiddleThumb173x182 = 'middle_thumb_173x182',
  MiddleThumb182x182 = 'middle_thumb_182x182',
  MiddleThumb200x200 = 'middle_thumb_200x200',
  BigThumb235x157 = 'big_thumb_235x157',
  BigThumb355x237 = 'big_thumb_355x237',
  BigThumb = 'big_thumb', // 250x250
  BigThumb350x350 = 'big_thumb_350x350',
  BigThumb538x435 = 'big_thumb_538x435', // fit, 538x538
  FullScreenThumb = 'full_screen_thumb', // 640x480
  FullScreenThumb1000x850 = 'full_screen_thumb_1000x850' // fit, 1000x1000
}

export enum FileAttachmentItemImageVersions {
  MiniThumb30x30 = 'mini_thumb_30x30',
  MiniThumb48x48 = 'mini_thumb_48x48',
  MiniThumb96x96 = 'mini_thumb_96x96',
  MiniThumb144x144 = 'mini_thumb_144x144',
  MiniThumb320x320 = 'mini_thumb_320x320',
  MiniThumb640x640 = 'mini_thumb_640x640',
  MiniThumb960x960 = 'mini_thumb_960x960',
  Thumb170x110 = 'thumb_170x110',
  Thumb200x160 = 'thumb_200x160',
  Thumb120x120 = 'thumb_120x120', // 115x115
  BigThumb538x435 = 'big_thumb_538x435',
  BigThumb538x435Fit = 'big_thumb_538x435_fit',
  FullScreenThumb1000x850 = 'full_screen_thumb_1000x850', // fit, 840x840
  WatermarkFullScreenThumb1000x850 = 'watermark_full_screen_thumb_1000x850' // fit, 840x840
}

export enum ClientFileItemImageVersions {
  Thumb = 'thumb', // 488x488
  MiniThumb48x48 = 'mini_thumb_48x48',
  MiniThumb96x96 = 'mini_thumb_96x96',
  MiniThumb144x144 = 'mini_thumb_144x144',
  MiniThumb320x320 = 'mini_thumb_320x320',
  MiniThumb640x640 = 'mini_thumb_640x640',
  MiniThumb960x960 = 'mini_thumb_960x960'
}

export enum SourceFileItemImageVersions {
  MiniThumb48x48 = 'mini_thumb_48x48',
  MiniThumb96x96 = 'mini_thumb_96x96',
  MiniThumb144x144 = 'mini_thumb_144x144',
  MiniThumb320x320 = 'mini_thumb_320x320',
  MiniThumb640x640 = 'mini_thumb_640x640',
  MiniThumb960x960 = 'mini_thumb_960x960',
  Thumb170x110 = 'thumb_170x110',
  Thumb200x160 = 'thumb_200x160',
  FullScreenThumb1000x850 = 'full_screen_thumb_1000x850' // fit, 840x840
}

export enum TextureFileItemImageVersions {
  MiniThumb48x48 = 'mini_thumb_48x48',
  MiniThumb96x96 = 'mini_thumb_96x96',
  MiniThumb144x144 = 'mini_thumb_144x144',
  MiniThumb320x320 = 'mini_thumb_320x320',
  MiniThumb640x640 = 'mini_thumb_640x640',
  MiniThumb960x960 = 'mini_thumb_960x960',
  MiniThumb30x30 = 'mini_thumb_30x30',
  Thumb170x110 = 'thumb_170x110',
  Thumb200x160 = 'thumb_200x160',
  Thumb120x120 = 'thumb_120x120', // 115x115
  BigThumb538x435 = 'big_thumb_538x435',
  FullScreenThumb1000x850 = 'full_screen_thumb_1000x850' // fit, 840x840
}

export type ImageVersions =
  | ImageItemImageVersions
  | FileAttachmentItemImageVersions
  | ClientFileItemImageVersions
  | SourceFileItemImageVersions
  | TextureFileItemImageVersions;

export type ImageSrcSetVersions = {
  '1x'?: ImageVersions;
  '1.5x'?: ImageVersions;
  '2x'?: ImageVersions;
  '2.5x'?: ImageVersions;
  '3x'?: ImageVersions;
};
